import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  VolumeLadder as BaseVolumeLadder,
  MixpanelEvent,
  MixpanelEventProperty,
  WL_USE_SYMBOL_SELECTOR,
  primeOrderForm,
  useDeviceType,
  useHistoricalPrices,
  useMarketDataSnapshot,
  useMixpanel,
  useSecurity,
  useWLOrderFormDispatch,
  useWLOrgConfigContext,
  useWLPriceLadderConfig,
  useWLRoleAuth,
  useWLSymbol,
  type ResolutionEnumNoPrefix,
  type SideEnum,
  type VolumeLadderProps,
} from '@talos/kyoko';
import Big from 'big.js';

import { isEqual } from 'lodash';
import { useHistory } from 'react-router-dom';

export const VolumeLadder = () => {
  const [showSettings, setShowSettings] = useState(false);
  const [topOfBookSizes, setTopOfBookSizes] = useState<string[]>([]);
  const { symbol, setSymbol } = useWLSymbol();
  const security = useSecurity(symbol);
  const mixpanel = useMixpanel();
  const [sizeBuckets, setSizeBuckets] = useState<string[]>([]);
  const [initialSizeBuckets, setInitialSizeBuckets] = useState<string[]>([]);
  const [resolution, setResolution] = useState<ResolutionEnumNoPrefix>('1M');
  const [initialResolution, setInitialResolution] = useState<ResolutionEnumNoPrefix>('1M');
  const { priceLadderSymbolConfig, setPriceLadderSymbolConfig } = useWLPriceLadderConfig();
  const [errors, setErrors] = useState<boolean[]>([]);
  const { config } = useWLOrgConfigContext();
  const { isAuthorized } = useWLRoleAuth();

  const defaultSizeBuckets = useMemo(
    () => (security?.SizeBuckets ?? []).map(bucket => (typeof bucket === 'string' ? bucket : bucket.Size)) ?? [],
    [security?.SizeBuckets]
  );

  useEffect(() => {
    const sizeBuckets: string[] = priceLadderSymbolConfig?.sizeBuckets ?? defaultSizeBuckets;
    setSizeBuckets(prev => sizeBuckets ?? prev);
    setResolution(prev => priceLadderSymbolConfig?.resolution ?? prev);
  }, [priceLadderSymbolConfig, security, symbol, defaultSizeBuckets]);

  useEffect(() => {
    setTopOfBookSizes([security?.MinPriceIncrement ?? '0.01']);
  }, [security]);

  const { marketDataSnapshots: marketDataObservable } = useMarketDataSnapshot({
    clearOnSymbolChange: true,
    sizeBuckets: priceLadderSymbolConfig?.sizeBuckets ?? defaultSizeBuckets,
    symbol,
    tag: `VOLUME_LADDER_STEPS`,
    priceIncrement: security?.MinPriceIncrement,
    includeTradeDirection: false,
  });

  const { marketDataSnapshots: marketViewObservable } = useMarketDataSnapshot({
    clearOnSymbolChange: true,
    sizeBuckets: topOfBookSizes,
    symbol,
    tag: `VOLUME_LADDER_TOB`,
    priceIncrement: security?.MinPriceIncrement,
    includeTradeDirection: false,
  });

  const sparklineDataObservable = useHistoricalPrices({ clearOnSymbolChange: true, symbol, resolution });

  const orderFormDispatch = useWLOrderFormDispatch();

  const deviceType = useDeviceType();
  const isMobile = deviceType === 'mobile' && !!config.allowMobile;
  const history = useHistory();

  const handleClickRow = useCallback(
    ({ price, side, size }) => {
      mixpanel.track(MixpanelEvent.ClickLadderRow, { [MixpanelEventProperty.Side]: side });
      orderFormDispatch(
        primeOrderForm({
          Side: side as SideEnum,
          Price: Big(price).toFixed(),
          Symbol: security?.Symbol,
          OrderQty: size != null ? Big(size).toFixed() : undefined,
        })
      );
      if (isMobile) {
        history.push('/trade');
      }
    },
    [history, isMobile, mixpanel, orderFormDispatch, security?.Symbol]
  ) satisfies VolumeLadderProps['onClickRow'];

  const handleChangeSizeBuckets = useCallback(
    (sizeBuckets: string[]) => {
      mixpanel.track(MixpanelEvent.ChangeSizeBuckets);
      setSizeBuckets(sizeBuckets);
    },
    [mixpanel]
  );

  const handleResetSizeBuckets = useCallback(() => {
    setPriceLadderSymbolConfig(symbol, { resolution, sizeBuckets: undefined });
    setSizeBuckets(defaultSizeBuckets);
    setErrors([]);
  }, [defaultSizeBuckets, resolution, setPriceLadderSymbolConfig, symbol]);

  const handleChangeResolution = useCallback(
    (resolution: ResolutionEnumNoPrefix) => {
      mixpanel.track(MixpanelEvent.ChangeLadderResolution);
      setResolution(resolution);
    },
    [mixpanel]
  );

  const handleSettingsClick = useCallback(() => {
    setShowSettings(prev => {
      if (prev) {
        setResolution(initialResolution);
        setSizeBuckets(initialSizeBuckets);
        return false;
      } else {
        setInitialResolution(resolution);
        setInitialSizeBuckets(sizeBuckets);
        return true;
      }
    });
  }, [resolution, initialResolution, sizeBuckets, initialSizeBuckets]);

  const handleSaveSettings = useCallback(() => {
    setPriceLadderSymbolConfig(symbol, {
      resolution,
      // If the size buckets equal the default configuration, don't save to config
      sizeBuckets: isEqual(defaultSizeBuckets, sizeBuckets) ? undefined : sizeBuckets,
    });
    setShowSettings(false);
  }, [defaultSizeBuckets, sizeBuckets, setPriceLadderSymbolConfig, symbol, resolution]);

  const handleChangeSymbol = useCallback(
    (symbol?: string) => {
      if (symbol) {
        setSymbol(symbol);
      }
    },
    [setSymbol]
  );

  return (
    <BaseVolumeLadder
      resolution={resolution}
      onClickRow={handleClickRow}
      security={security}
      sizeBuckets={sizeBuckets}
      showSettings={showSettings}
      onSymbolChange={handleChangeSymbol}
      onSettingsClick={handleSettingsClick}
      onSaveSettings={handleSaveSettings}
      onChangeResolution={handleChangeResolution}
      onChangeSizeBuckets={handleChangeSizeBuckets}
      onResetSizeBuckets={handleResetSizeBuckets}
      marketDataObservable={marketDataObservable}
      marketViewObservable={marketViewObservable}
      sparklineDataObservable={sparklineDataObservable}
      errors={errors}
      setErrors={setErrors}
      showSpread={config.showSpreadOnVolumeLadder}
      bpsIncrement={config.volumeLadderDecimalPrecision}
      disableCustomLadderSizeBuckets={config.disableCustomLadderSizeBuckets}
      showDisclaimer={config.showDisclaimer}
      disabledSymbolSelector={!isAuthorized(WL_USE_SYMBOL_SELECTOR)}
      usePreciseTopOfBookIncrement={config.usePreciseTopOfBookIncrement}
    />
  );
};
