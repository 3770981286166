import {
  AutocompleteDropdown,
  Box,
  Button,
  FormControlSizes,
  FormattedMessage,
  FuseAutocompleteResult,
  IconName,
  Input,
  Z_INDEX,
  logger,
  useDropdownPopper,
  useIntl,
  useSearchSelect,
  useWLLayoutArrangement,
} from '@talos/kyoko';
import { useCallback, useRef } from 'react';
import { defineMessages } from 'react-intl';
import type { LayoutComponentArrangement, MultiLayoutComponent } from '../../../layouts/types';
import { useCurrentLayoutComponent } from '../useCurrentLayoutComponent';

const messages = defineMessages({
  editLayout: {
    id: 'Layout.Header.editLayout',
    defaultMessage: 'Edit Layout',
  },
  singleSymbolView: {
    id: 'Layout.Header.singleSymbolView',
    defaultMessage: 'Single Symbol View',
  },
  marketDataCards: {
    id: 'Layout.Header.marketDataCards',
    defaultMessage: 'Market Data Cards',
  },
  candlesticksAndMarketDataCards: {
    id: 'Layout.Header.candlesticksAndMarketDataCards',
    defaultMessage: 'Candlesticks + Market Data Cards',
  },
  watchlistAndMarketDataCards: {
    id: 'Layout.Header.watchlistAndMarketDataCards',
    defaultMessage: 'Watchlist + Market Data Cards',
  },
  watchlistCandlesticksMarketDataCards: {
    id: 'Layout.Header.watchlistCandlesticksMarketDataCards',
    defaultMessage: 'Watchlist, Candlesticks, Market Data Cards',
  },
});

export const EditLayoutButtonInner = ({ items }: { items: LayoutComponentArrangement[] }) => {
  const layoutComponent = useCurrentLayoutComponent();
  const { layoutArrangement, setLayoutArrangement } = useWLLayoutArrangement();
  const { formatMessage } = useIntl();

  const getLabel = useCallback(
    (arrangement: LayoutComponentArrangement) => {
      if (arrangement.intlKey && arrangement.intlKey in messages) {
        return formatMessage(messages[arrangement.intlKey]);
      }
      return arrangement.displayName;
    },
    [formatMessage]
  );

  const handleUpdateArrangement = useCallback(
    (newArrangement?: LayoutComponentArrangement) => {
      if (newArrangement == null) {
        logger.error(new Error('newArrangement is null'));
        return;
      }
      if ('arrangements' in layoutComponent) {
        const newArrangementName = Object.keys((layoutComponent as MultiLayoutComponent).arrangements).find(
          key => (layoutComponent as MultiLayoutComponent).arrangements[key].displayName === newArrangement.displayName
        );
        if (newArrangementName) {
          setLayoutArrangement(newArrangementName);
        }
      }
    },
    [layoutComponent, setLayoutArrangement]
  );

  const inputRef = useRef(null);

  const searchSelect = useSearchSelect<LayoutComponentArrangement>({
    selection:
      'arrangements' in layoutComponent
        ? (layoutComponent as MultiLayoutComponent).arrangements[layoutArrangement]
        : undefined,
    items,
    inputRef,
    initialSortByLabel: false,
    getLabel,
    onChange: handleUpdateArrangement,
    scrollIntoView: target => {
      if (target) {
        (target as any)['scrollIntoViewIfNeeded']
          ? (target as any)['scrollIntoViewIfNeeded']()
          : target.scrollIntoView(false);
      }
    },
  });

  const { getInputProps, openMenu, isOpen } = searchSelect;

  const dropdownReferenceElement = useRef<HTMLButtonElement | null>(null);
  const dropdownPopper = useDropdownPopper({
    isOpen,
    dropdownWidth: '350px',
    dropdownPlacement: 'bottom-end',
    referenceElement: dropdownReferenceElement.current ?? null,
  });

  return (
    <div>
      <Button prominent ref={dropdownReferenceElement} onClick={openMenu} startIcon={IconName.Template}>
        <FormattedMessage {...messages.editLayout} />
      </Button>
      <Box zIndex={Z_INDEX.dropdown}>
        <AutocompleteDropdown
          {...searchSelect}
          {...dropdownPopper}
          renderResult={FuseAutocompleteResult}
          size={FormControlSizes.Default}
          childrenAboveResults={<Input style={{ display: 'none' }} {...getInputProps({ ref: inputRef })} />}
        />
      </Box>
    </div>
  );
};
