import { useMemo } from 'react';
import { getAgGridColId } from '../components/BlotterTable/columns/getAgGridColId';
import type { Column, ColumnDef } from '../components/BlotterTable/columns/types';
import { useDefaultColumns } from '../components/BlotterTable/useDefaultColumns';
import type { ExpectTrue } from '../tests';
import { EMPTY_ARRAY } from '../utils/empty';
import type { ExecTypeEnum, ITradeCapture, SideEnum, TradeStatusEnum } from './types';

// TradeCapture4046
export class MarketTrade {
  static readonly rowID = 'EID';
  static readonly defaultColumns = [
    'TransactTime',
    'Side',
    'Symbol',
    'Quantity',
    'Price',
    'Amount',
    'Fee',
    'TradeID',
    'Market',
    'OrderID',
    'TradeStatus',
  ] satisfies (keyof MarketTrade)[];

  Timestamp!: string;

  Market!: string;

  CredentialID!: string;

  Symbol!: string;

  Currency?: string;

  AmountCurrency!: string;

  FeeCurrency!: string;

  DealtCurrency!: string;

  OrderID?: string;

  TradeID?: string;

  TransactTime!: string;

  ExecType!: ExecTypeEnum;

  Side!: SideEnum;

  Price!: string;

  Quantity!: string;

  Amount!: string;

  Fee?: string;

  TradeStatus!: TradeStatusEnum;

  OID?: string;

  EID?: string;

  ClOrdID?: string;

  QID?: string;

  OrgID?: number;

  AggressorSide?: SideEnum;
  MarketAccount?: string;
  ParentService?: unknown;
  MessageID?: unknown;

  Attributes?: unknown;
  FixingDetails?: {
    Index?: string;
    Fixing?: string;
  };

  TalosFee?: string;
  TalosFeeCurrency?: string;

  constructor(data: MarketTrade) {
    Object.assign(this, data);
  }
}

interface UseMarketTradeColumns {
  defaultColumns?: (keyof MarketTrade | Partial<Column>)[];
}

export function useMarketTradeColumns({ defaultColumns = EMPTY_ARRAY }: UseMarketTradeColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            { field: 'TransactTime', type: 'date', sortable: true, sort: '-' },
            { field: 'Symbol', type: 'security' },
            { field: 'Side', type: 'side' },
            { field: 'Quantity', title: 'Filled Quantity', type: 'size', params: { currencyField: 'Currency' } },
            { field: 'Price', title: 'Filled Price', type: 'price', params: { assetField: 'Symbol' } },
            { field: 'Amount', title: 'Filled Counter Amt', type: 'size', params: { currencyField: 'AmountCurrency' } },
            { field: 'Fee', type: 'size', params: { currencyField: 'FeeCurrency' } },
            { field: 'TradeID', type: 'id' },
            { field: 'MarketAccount', type: 'marketAccount' },
            { field: 'OrderID', type: 'id' },
            { field: 'TradeStatus', type: 'tradeStatus' },
          ] satisfies ColumnDef<MarketTrade>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );
  const defaultHiddenColumns = useMemo(() => {
    return new Map(
      (
        [
          { field: 'AggressorSide', type: 'side' },
          { field: 'Market', type: 'market' },
          { field: 'ClOrdID', type: 'id' },
          { field: 'QID', type: 'text' },
          { field: 'OID', type: 'text' },
          { field: 'EID', type: 'text' },
          { field: 'Timestamp', type: 'date' },
          { field: 'ExecType', type: 'text' },
          { field: 'CredentialID', type: 'text' },
        ] satisfies ColumnDef<MarketTrade>[]
      ).map(c => [getAgGridColId(c), { ...c, hide: true }])
    );
  }, []);

  const columnDefinitions = useMemo(() => {
    return new Map(
      (
        [
          ...defaultVisibleColumns.values(),
          ...defaultHiddenColumns.values(),
        ] satisfies ColumnDef<MarketTrade>[] as Column[]
      ).map(c => [getAgGridColId(c), c])
    );
  }, [defaultVisibleColumns, defaultHiddenColumns]);

  return useDefaultColumns(defaultColumns, columnDefinitions);
}

// TYPE LEVEL TESTS
type _Expect_MarketTrade_To_Only_Have_Keys_From_ITradeCapture = ExpectTrue<
  {
    [K in keyof MarketTrade & string]: K extends keyof ITradeCapture ? true : K;
  }[Exclude<keyof MarketTrade, never>]
>;
type _Expect_All_Keys_In_ITradeCapture_To_Be_In_MarketTrade = ExpectTrue<
  {
    [K in keyof ITradeCapture & string]: K extends keyof MarketTrade ? true : K;
  }[Exclude<keyof ITradeCapture, never>]
>;
