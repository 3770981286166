import { cloneDeep, entries, get, noop, toNumber, uniqueId } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useConstant } from '../../hooks';
import { bpsToPercent, percentToBps, prettyName } from '../../utils';
import { Button, ButtonVariants } from '../Button';
import { Box, VStack } from '../Core';
import { Divider } from '../Divider';
import type { EntityPageRecord } from '../EntityAdminPage';
import { FormGroup, Input, SearchSelect } from '../Form';
import { DrawerContent, DrawerFooter } from './styles';

export type InputsAndDropdownsDrawerDropdownOption = { value: string; label: string; description?: string };

/**
 * The InputsAndDropdownsDrawerOption is passed as a drawerOption to InputsAndDropdownsDrawer
 * @required
 * @param field The field of the drawer option.
 * @param type divider, input, inputBPS, inputNumeric, dropdown
 * @param options An array of dropdown options if { type: 'dropdown' }
 * @optional
 * @param title The title of the drawer option.
 * @param required Whether the drawer option is required. Defaults to false.
 * @param disabledWhenEditing Whether the drawer option is disabled when editing.
 * @param placeholder The placeholder of the drawer option.
 * @param hideField Fn for "is drawer option hidden".
 * @param disableField Fn for "is drawer option disabled".
 */
export type InputsAndDropdownsDrawerOption<TDrawerRecord extends EntityPageRecord> =
  | ({
      field: keyof TDrawerRecord;
      title?: string;
      required?: boolean;
      disabledWhenEditing?: boolean;
      placeholder?: string;
      hideField?: (form: TDrawerRecord) => boolean;
      disableField?: (form: TDrawerRecord) => boolean;
    } & (
      | { type: 'input' | 'inputBPS' | 'inputNumeric' }
      | { type: 'dropdown'; options: InputsAndDropdownsDrawerDropdownOption[] }
    ))
  | { type: 'divider' };

type InputsAndDropdownsDrawerProps<TRecord extends EntityPageRecord, TDrawerRecord extends TRecord> = {
  onSave: (modifiedEntity: TDrawerRecord) => Promise<TRecord>;
  onDelete: (selectedEntity: TRecord) => Promise<void>;
  allowDeleteEntity?: boolean;
  selectedEntity?: TRecord;
  drawerOptions: InputsAndDropdownsDrawerOption<TDrawerRecord>[];
  isEditing: boolean;
};

const isOptionDivider = <TDrawerRecord extends EntityPageRecord>(
  option: InputsAndDropdownsDrawerOption<TDrawerRecord>
): option is { type: 'divider' } => option.type === 'divider';

const getOptionLabel = <TDrawerRecord extends EntityPageRecord>(
  option: InputsAndDropdownsDrawerOption<TDrawerRecord>
) => {
  if (isOptionDivider(option)) {
    return null;
  }
  return `${option.title || prettyName(String(option.field))}${option.required ? '*' : ''}`;
};

const getOptionKey = <TDrawerRecord extends EntityPageRecord>(option: InputsAndDropdownsDrawerOption<TDrawerRecord>) =>
  isOptionDivider(option) ? uniqueId('inputs-and-dropdowns-drawer-divider') : String(option.field);

const getFormState = <TRecord extends EntityPageRecord, TDrawerRecord extends TRecord>(
  entity: TRecord | undefined,
  drawerOptions: InputsAndDropdownsDrawerOption<TDrawerRecord>[]
) => {
  return entries(entity).reduce<TDrawerRecord>((acc, [field, value]) => {
    const drawerOption = drawerOptions.find(option => !isOptionDivider(option) && option.field === field);
    if (drawerOption?.type === 'inputBPS') {
      // Convert percent to BPS for form initialization
      acc[field as keyof TDrawerRecord] = (value
        ? percentToBps(value as number)
        : '') as unknown as TDrawerRecord[keyof TRecord];
    } else {
      // Initialize rest of the form with empty strings
      acc[field as keyof TDrawerRecord] = (value ?? '') as TDrawerRecord[keyof TRecord];
    }

    return acc;
  }, {} as TDrawerRecord);
};

const hideDrawerOption = <TDrawerRecord extends EntityPageRecord>(
  option: InputsAndDropdownsDrawerOption<TDrawerRecord>,
  form: TDrawerRecord
): boolean => (option.type !== 'divider' && option.hideField?.(form)) ?? false;

export function InputsAndDropdownsDrawer<TRecord extends EntityPageRecord, TDrawerRecord extends TRecord>({
  selectedEntity,
  onSave,
  allowDeleteEntity,
  onDelete,
  drawerOptions,
  isEditing,
}: InputsAndDropdownsDrawerProps<TRecord, TDrawerRecord>) {
  const [form, setForm] = useState<TDrawerRecord>(getFormState(selectedEntity, drawerOptions));

  const handleOnFormUpdate = useConstant((field: keyof TDrawerRecord, value: string) => {
    setForm(prev => ({ ...prev, [field]: value }));
  });

  const handleDelete = useCallback(() => {
    if (window.confirm('Are you sure you want to delete this entity?')) {
      onDelete(selectedEntity!);
    }
  }, [onDelete, selectedEntity]);

  const handleOnSave = useCallback(() => {
    const formWithInputTypesConverted = drawerOptions.reduce((acc, option) => {
      if (option.type === 'inputBPS' && form[option.field]) {
        // Convert BPS to percent for saving
        acc[option.field] = bpsToPercent(form[option.field]) as TDrawerRecord[keyof TDrawerRecord];
      } else if (option.type === 'inputNumeric' && form[option.field]) {
        // Convert string to number for saving
        acc[option.field] = toNumber(form[option.field]) as TDrawerRecord[keyof TDrawerRecord];
      }
      return acc;
    }, cloneDeep(form));

    onSave(formWithInputTypesConverted)
      .then(entity => setForm(getFormState(entity, drawerOptions)))
      .catch(noop);
  }, [drawerOptions, form, onSave]);

  const someRequiredInputNotPopulated = useMemo(
    () =>
      drawerOptions.some(
        option =>
          // Divider options are ignored
          !isOptionDivider(option) &&
          option.required &&
          !form[option.field] &&
          // If the option is hidden, it is not required.
          !hideDrawerOption(option, form)
      ),
    [drawerOptions, form]
  );

  return (
    <VStack data-testid="entity-admin-page-inputs-and-dropdowns-drawer" h="100%">
      <DrawerContent overflow="overlay" w="100%">
        <Box>
          {drawerOptions
            .filter(drawerOption => !hideDrawerOption(drawerOption, form))
            .map(drawerOption => (
              <FormGroup key={getOptionKey(drawerOption)} label={getOptionLabel(drawerOption)}>
                {isOptionDivider(drawerOption) ? (
                  <Divider data-testid="inputs-and-dropdowns-drawer-divider" />
                ) : ['input', 'inputBPS', 'inputNumeric'].includes(drawerOption.type) ? (
                  <Input
                    value={(get(form, drawerOption.field) as string | undefined) ?? ''}
                    onChange={e => handleOnFormUpdate(drawerOption.field, e.target.value)}
                    disabled={(drawerOption.disabledWhenEditing && isEditing) || drawerOption.disableField?.(form)}
                    placeholder={drawerOption.placeholder}
                    suffix={drawerOption.type === 'inputBPS' ? 'BPS' : undefined}
                    inputType="text"
                    data-testid={`inputs-and-dropdowns-drawer-${String(drawerOption.field)}`}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                  />
                ) : drawerOption.type === 'dropdown' ? (
                  <SearchSelect
                    selection={drawerOption.options.find(option => option.value === form[drawerOption.field])}
                    options={drawerOption.options}
                    getLabel={option => option.label}
                    getDescription={option => option.description ?? ''}
                    onChange={newValue => handleOnFormUpdate(drawerOption.field, newValue?.value ?? '')}
                    showClear={true}
                    disabled={drawerOption.disabledWhenEditing && isEditing}
                    placeholder={drawerOption.placeholder}
                    data-testid={`inputs-and-dropdowns-drawer-${String(drawerOption.field)}`}
                  />
                ) : null}
              </FormGroup>
            ))}
        </Box>
      </DrawerContent>
      <DrawerFooter w="100%">
        {allowDeleteEntity && isEditing && (
          <Button
            variant={ButtonVariants.Negative}
            onClick={handleDelete}
            data-testid="inputs-and-dropdowns-drawer-delete-button"
          >
            Delete
          </Button>
        )}
        <Button
          onClick={handleOnSave}
          variant={ButtonVariants.Primary}
          data-testid="inputs-and-dropdowns-drawer-save-button"
          disabled={someRequiredInputNotPopulated}
        >
          Save
        </Button>
      </DrawerFooter>
    </VStack>
  );
}
