import { OrdStatusEnum, type SideEnum, type WebsocketRequest } from '@talos/kyoko';
export const ACTIVE_ORDER_STATUSES = [
  OrdStatusEnum.New,
  OrdStatusEnum.PendingNew,
  OrdStatusEnum.PendingCancel,
  OrdStatusEnum.PendingReplace,
  OrdStatusEnum.PartiallyFilled,
  OrdStatusEnum.Holding,
  OrdStatusEnum.Replaced,
];

export interface ActiveOrdersWebsocketRequest extends WebsocketRequest {
  MarketAccounts?: string[];
  Sides?: SideEnum[];
  Statuses?: OrdStatusEnum[];
  Strategies?: string[];
  Symbols?: string[];
}
const DEFAULT_SORT = '-SubmitTime';
export const ActiveOrdersRequest = {
  name: 'Order',
  tag: 'ACTIVE_ORDERS',
  HideApiCalls: true,
  Throttle: '1s',
  Statuses: ACTIVE_ORDER_STATUSES,
  sort_by: DEFAULT_SORT,
  StartDate: Sugar.Date.create('a year ago'),
} as const satisfies ActiveOrdersWebsocketRequest;
