import type { MarketAccount } from '../contexts';
import { TRANSFER } from '../tokens';
import { toBig, truncateBlockchainAddress } from '../utils';
import { type IMessageID, type ITransfer, TransferStatusEnum } from './types';

const TERMINAL_TRANSFER_STATES = [TransferStatusEnum.Completed, TransferStatusEnum.Rejected];
const TERMINAL_TRANSFER_STATES_SET = new Set(TERMINAL_TRANSFER_STATES);

export class Transfer {
  public static StreamName = TRANSFER;

  TransferID!: string;
  Market!: string;
  OwnerAccountID!: number;
  Amount!: string;
  Currency!: string;
  Status!: TransferStatusEnum;
  Timestamp!: string;
  TransactTime!: string;
  CredentialID!: number;
  ClientID!: string;
  User!: string;
  SubmitTime!: string;
  FromMarketAccountID!: number;
  ToMarketAccountID!: number;
  EffectiveDate?: string;
  ChainCurrencySymbol?: string;
  FeeAmount?: string;
  FeeCurrency?: string;
  OrgID!: number;
  ParentTransactionID?: string;
  MarketTransferStatus?: string;
  MarketTransferID?: string;
  OwnerSourceAccountID?: string;
  FromSourceAccountID?: string;
  ToSourceAccountID?: string;
  Description?: string;
  Text?: string;
  ReferenceData?: string;
  MarketDescription?: string;
  SourceData?: number;
  MessageID?: IMessageID;
  FromAddress?: string;
  ToAddress?: string;
  TxHash?: string;
  TxHashes?: string[];

  private getCounterpartyAddressTruncated(address: string | undefined) {
    return address ? truncateBlockchainAddress(address) : undefined;
  }

  get counterpartyAccountID() {
    return this.isTransferOutgoing ? this.ToMarketAccountID : this.FromMarketAccountID;
  }

  get counterpartySourceAccountID() {
    return this.isTransferOutgoing ? this.ToSourceAccountID : this.FromSourceAccountID;
  }

  get counterpartyAddress() {
    return this.isTransferOutgoing ? this.ToAddress : this.FromAddress;
  }

  /** The transferDescription is a front-end computed readable description based on the properties of the transfer.
   *
   * This is different from Transfer.Description, which is sent from the backend and is currently presented to the user in the column "Note".
   */
  getTransferDescription(
    marketAccountsByID: Map<number, MarketAccount> | undefined,
    avoidMarketAccountIdDisplay: boolean
  ) {
    // The counterparty account being an external account means that we don't have it represented as a Talos MarketAccount.
    // When this happens, we instead look at the SourceAccountIDs and the Addresses (blockchain addresses) in order to
    // try to display some useful description to the user.
    if (this.isCounterpartyExternalAccount) {
      return `Transfer ${this.isTransferOutgoing ? 'TO' : 'FROM'} External ID: ${
        this.counterpartySourceAccountID ?? this.getCounterpartyAddressTruncated(this.counterpartyAddress) ?? 'unknown'
      }`;
    }

    // Base case: we do have the counterpartyAccountID represented as a Talos MarketAccount, and so we grab its display name.
    return `Transfer ${this.isTransferOutgoing ? 'TO' : 'FROM'} ${
      marketAccountsByID?.get(this.counterpartyAccountID)?.DisplayName ??
      (avoidMarketAccountIdDisplay ? 'unknown' : `ID: ${this.counterpartyAccountID}`)
    }`;
  }

  /**
   * Returns whether or not the counterparty account in this transfer is an "external" account or not.
   *
   * An external account is essentially one that is not a Talos MarketAccount.
   */
  get isCounterpartyExternalAccount() {
    return this.counterpartyAccountID === 0;
  }

  /** Returns the Amount property of the transfer, but as a negative number if the transfer is outgoing */
  get directionalAmount() {
    const bigAmount = toBig(this.Amount);
    if (!bigAmount) {
      return undefined;
    }

    return this.isTransferOutgoing ? bigAmount.times(-1).toFixed() : bigAmount.toFixed();
  }

  getAccountDisplay(
    marketAccountsByID: Map<number, MarketAccount> | undefined,
    type: 'source' | 'destination',
    /** Avoid showing data (like IDs) about market accounts outside of scope */
    avoidMarketIdDisplay: boolean
  ) {
    let accountID: number | undefined;
    let source: string | undefined;
    let address: string | undefined;
    switch (type) {
      case 'source':
        accountID = this.FromMarketAccountID;
        source = this.FromSourceAccountID;
        address = this.FromAddress;
        break;
      case 'destination':
        accountID = this.ToMarketAccountID;
        source = this.ToSourceAccountID;
        address = this.ToAddress;
        break;
    }

    if (accountID && accountID !== 0) {
      const marketAccount = marketAccountsByID?.get(accountID);
      const marketAccountDisplayableName = marketAccount ? marketAccount.DisplayName ?? marketAccount.Name : undefined;
      const result = marketAccountDisplayableName ?? (avoidMarketIdDisplay ? 'Unknown' : `ID: ${accountID}`);
      return result;
    }
    return source ? `External ID: ${source}` : this.getCounterpartyAddressTruncated(address) ?? 'External ID: unknown';
  }

  /** Whether or not this transfer represents a reduction in the user's balance */
  get isTransferOutgoing() {
    return this.OwnerAccountID === this.FromMarketAccountID;
  }

  get rowID() {
    return this.TransferID;
  }

  /** Whether or not the transfer is in a terminal state */
  get isTerminal() {
    return TERMINAL_TRANSFER_STATES_SET.has(this.Status);
  }

  constructor(data: Transfer | ITransfer) {
    Object.assign(this, data);
  }
}
