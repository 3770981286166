import {
  CustomerUserConfigLayoutNavigationTypes,
  CustomerUserConfigLayoutType,
  ResizerPlacement,
  WL_BLOTTER_TAB,
} from '@talos/kyoko';

import { Blotters } from '../components/Blotters/Blotters';
import { RFQOrderForm } from '../components/OrderForms/RFQForm';
import { BLOTTERS_MIN_HEIGHT, MAIN_HEADER_HEIGHT, VOLUME_LADDER_HEIGHT } from '../styles/dimensions';
import { simpleRFQMobileScreens } from './mobileScreens';
import type { LayoutComponent } from './types';

// Re-export these under their original names for convenience, until all consumers can be redirected to @talos/kyoko
export { CustomerUserConfigLayoutType as LayoutType, CustomerUserConfigLayoutNavigationTypes as NavigationTypes };

const SimpleRFQLayoutBlotterTabs = [WL_BLOTTER_TAB.Balances, WL_BLOTTER_TAB.Credits, WL_BLOTTER_TAB.Transfers];
const TradesBlotterTab = [WL_BLOTTER_TAB.Trades];

export const SimpleRFQLayout: LayoutComponent = {
  headerSymbolSelector: false,
  maxWidth: 1440,
  columns: ['minmax(320px,24rem)', 'minmax(24rem,1fr)'],
  rows: ['minmax(100px,2fr)', 'minmax(100px,1fr)'],
  parts: [
    {
      column: '1',
      row: '1/3',
      component: <RFQOrderForm />,
    },
    {
      column: '2/5',
      row: '1',
      component: <Blotters blotterTabs={SimpleRFQLayoutBlotterTabs} />,
    },
    {
      column: '2/5',
      row: '2',
      minHeight: BLOTTERS_MIN_HEIGHT,
      maxHeight: window.innerHeight - MAIN_HEADER_HEIGHT - VOLUME_LADDER_HEIGHT - 20,
      component: <Blotters blotterTabs={TradesBlotterTab} />,
      resizable: ResizerPlacement.TOP,
    },
  ],
  mobileScreens: simpleRFQMobileScreens,
};

export const ViewOnlySimpleRFQLayout: LayoutComponent = {
  headerSymbolSelector: false,
  maxWidth: 1440,
  columns: ['minmax(24rem,1fr)'],
  rows: ['minmax(100px,2fr)', 'minmax(100px,1fr)'],
  parts: [
    {
      column: '1',
      row: '1',
      component: <Blotters blotterTabs={SimpleRFQLayoutBlotterTabs} />,
    },
    {
      column: '1',
      row: '2',
      minHeight: BLOTTERS_MIN_HEIGHT,
      maxHeight: window.innerHeight - MAIN_HEADER_HEIGHT - VOLUME_LADDER_HEIGHT - 20,
      component: <Blotters blotterTabs={TradesBlotterTab} />,
      resizable: ResizerPlacement.TOP,
    },
  ],
};
