import { createContext, useContext } from 'react';
import type { Counterparty } from '../types/Counterparty';
import type { ConnectionModeEnum } from '../types/types';
import type { User } from '../types/User';

export const UserContext = createContext<UserContextProps | undefined>(undefined);
UserContext.displayName = 'UserContext';

export function useUserContext() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('Missing UserContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export interface UserContextProps {
  isLoaded: boolean;
  user: User;
  counterparties?: Counterparty[];
  counterpartiesByName?: Map<string, Counterparty>;
  orgWsEndpoint: string;
  orgApiEndpoint: string;

  createApiKey(whitelistedIPs?: string[]): Promise<any>;

  deleteApiKey(): Promise<any>;

  updateApiKeyWhitelistedIPs(whitelistedIPs: string[]): Promise<any>;

  updateApiKeyRoles(roles: string[]): Promise<any>;

  listMarketConfigs(): Promise<MarketConfig[]>;

  listMarketCredentials(): Promise<MarketCredential[]>;

  createMarketCredential(marketName: string, values: CredentialsFormParams): Promise<any>;

  updateMarketCredential(marketName: string, credentialName: string, values: CredentialsFormParams): Promise<any>;

  patchMarketCredential(marketName: string, credentialName: string, values: CredentialsFormParams): Promise<any>;

  deleteMarketCredential(marketName: string, credentialName: string): Promise<any>;

  refetchCounterparties(): void;
}

export type CredentialsFormParams = { [key: string]: string | Blob | undefined };

export interface MarketCredential {
  Name: string;
  Label: string; // Treat as a Display Name
  ConnectionType: string;
  CredentialID: number;
  Market: string;
  Mode: ConnectionModeEnum;
  Attributes?: Record<string, string>;
  SecretsUpdatedAt?: string;
  AlertMutedUntil?: string;
}

export type CredentialTemplateFields = {
  label: string;
  name: string;
  required: boolean;
  cleartext?: boolean;
  preview?: { first?: number; last?: number };
  type?: 'file' | 'textarea' | 'checkbox' | 'select';
  values?: string[];
};

export type CredentialTemplate = {
  type: string;
  fields: CredentialTemplateFields[];
  displayName: string;
};

export interface MarketConfig {
  credentials: CredentialTemplate[];
  feesDescription: string;
  feesUrl: string;
  guide: {
    header: string;
    image: string;
    text: string;
  }[];
  label: string;
  name: string;
  settingsUrl: string;
}
