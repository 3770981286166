import { css, type DefaultTheme } from 'styled-components';
import { FormControlSizes } from '../Form/types';

export const getButtonBorderRadius = (theme: DefaultTheme, size = FormControlSizes.Default) => {
  switch (size) {
    case FormControlSizes.Xxs: {
      return theme.borderRadiusButtonTiny;
    }
    case FormControlSizes.Tiny: {
      return theme.borderRadiusButtonTiny;
    }
    case FormControlSizes.Small: {
      return theme.borderRadiusButtonSmall;
    }
    case FormControlSizes.Large: {
      return theme.borderRadiusButtonLarge;
    }
    case FormControlSizes.Default:
    default: {
      return theme.borderRadiusButtonDefault;
    }
  }
};

export const clippedButtonCss = ({
  size,
  theme,
  customOffset,
}: {
  size?: FormControlSizes;
  theme: DefaultTheme;
  customOffset?: number;
}) =>
  css`
    --offset: ${customOffset
      ? customOffset * theme.chamferMultiplier
      : ((size ?? 2) * theme.baseSize * theme.chamferMultiplier) / 6}px;
    --offset-low: var(--offset);
    --offset-high: calc(100% - var(--offset));

    clip-path: polygon(
      100% var(--offset-low),
      var(--offset-high) 0%,
      var(--offset-low) 0%,
      0% var(--offset-low),
      0% var(--offset-high),
      var(--offset-low) 100%,
      var(--offset-high) 100%,
      100% var(--offset-high)
    );
    border-radius: 0;
    border-width: 0;
  `;
