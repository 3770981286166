import {
  Box,
  Divider,
  FormattedMessage,
  FormControlSizes,
  FormGroup,
  IconButton,
  IconName,
  MixpanelEvent,
  MixpanelEventProperty,
  ThemeToggle,
  ThemeTypes,
  useAuthContext,
  useMixpanel,
  useScreenOrientation,
  useWLOrgConfigContext,
  useWLRoleAuth,
  useWLThemeType,
  useWLUser,
  type CustomerUser,
} from '@talos/kyoko';
import { noop } from 'lodash';
import { cloneElement, useCallback, useMemo, useState, type Dispatch, type SetStateAction } from 'react';
import { defineMessages } from 'react-intl';
import { Route, Switch } from 'react-router';
import { createGlobalStyle, useTheme } from 'styled-components';
import type { MobileLayoutComponentScreen } from '../../../layouts/mobileScreens';
import { MobileLabelMessages } from '../../../layouts/mobileScreens/messages';
import type { LayoutComponentScreen } from '../../../layouts/types';
import { Logo } from '../../Logo';
import { Part } from '../Part';
import { MobileHeader } from './MobileHeader';
import { MobileTabBar } from './MobileTabBar';
import { Menu, MenuItem, MobileLayoutWrapper, Overlay } from './styles';

const MENU_CUTOFF = 5;
export function MobileLayout({ screens }: { screens: MobileLayoutComponentScreen[] }) {
  const [isOpen, setIsOpen] = useState(false);
  const user = useWLUser();
  const { isAuthorized } = useWLRoleAuth();
  const { config } = useWLOrgConfigContext();

  const authorizedScreens = useMemo(() => {
    return screens.filter(
      screen =>
        (screen.requiredPermissions == null ||
          screen.requiredPermissions.every(permission => isAuthorized(permission))) &&
        (screen.requiredConfigs == null || screen.requiredConfigs.every(prop => config[prop]))
    );
  }, [config, isAuthorized, screens]);

  const tabBarItems = authorizedScreens.slice(0, MENU_CUTOFF);
  const menuItems = authorizedScreens.slice(MENU_CUTOFF);

  const orientation = useScreenOrientation();
  const isPortrait = orientation === 'portrait-primary' || orientation === 'portrait-secondary';

  return (
    <>
      <MobileGlobalStyles />
      <MobileLayoutWrapper
        isPortrait={isPortrait}
        flex="1"
        flexDirection="column"
        gap="spacingTiny"
        overflow="hidden"
        position="relative"
      >
        {isPortrait && <MobileHeader onMenuClick={setIsOpen} />}
        <Part resizing={false} onMouseDown={noop} column="1" row="1" flex="1 1 0" overflow="hidden" borderRadius={0}>
          <Switch>
            {authorizedScreens.map(screen => (
              <Route key={screen.path} path={screen.path} exact={true}>
                {cloneElement(screen.component, screen.params)}
              </Route>
            ))}
          </Switch>
        </Part>
        {isPortrait && (
          <>
            <MobileTabBar items={tabBarItems} />
            <MobileSidebarMenu isOpen={isOpen} setIsOpen={setIsOpen} menuItems={menuItems} user={user} />
          </>
        )}
      </MobileLayoutWrapper>
    </>
  );
}

const sidebarMessages = defineMessages({
  logOut: {
    id: 'Layout.MobileLayout.logOut',
    defaultMessage: 'Log out',
  },
  themeType: {
    id: 'Layout.MobileLayout.themeType',
    defaultMessage: 'Theme Type',
  },
});

function MobileSidebarMenu({
  isOpen,
  setIsOpen,
  menuItems,
  user,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  menuItems: LayoutComponentScreen[];
  user: CustomerUser;
}) {
  const theme = useTheme();
  const { themeType, setThemeType } = useWLThemeType();
  const mixpanel = useMixpanel();
  const { logout } = useAuthContext();

  const handleThemeType = useCallback(
    (themeType: ThemeTypes) => {
      mixpanel.track(MixpanelEvent.ChangeThemeType, { [MixpanelEventProperty.Type]: themeType });
      setThemeType(themeType);
    },
    [mixpanel, setThemeType]
  );

  return (
    <>
      <Menu isOpen={isOpen}>
        <Logo themeType={theme.type} />
        <Box
          position="absolute"
          right="spacingMedium"
          top={`calc(${theme.spacingMedium}px + env(safe-area-inset-top))`}
          opacity={0.5}
        >
          <IconButton onClick={() => setIsOpen(false)} size={FormControlSizes.Small} icon={IconName.Close} ghost />
        </Box>
        <Divider my="spacingDefault" />
        {menuItems.map(item => (
          <MenuItem key={item.path} to={item.path} onClick={() => setIsOpen(false)} startIcon={item.icon}>
            <FormattedMessage {...MobileLabelMessages[item.label]} />
          </MenuItem>
        ))}
        <Box mt="auto">
          <FormGroup
            px="spacingDefault"
            mb="spacingMedium"
            inline
            label={<FormattedMessage {...sidebarMessages.themeType} />}
            justifyContent="space-between"
          >
            <ThemeToggle
              themeType={themeType ?? ThemeTypes.dark}
              onChangeThemeType={handleThemeType}
              size={FormControlSizes.Default}
            />
          </FormGroup>
          <Divider my="spacingDefault" />
          <MenuItem onClick={logout} startIcon={IconName.Logout}>
            <FormattedMessage {...sidebarMessages.logOut} />
          </MenuItem>
          <Divider mb="spacingDefault" />
          <MenuItem startIcon={IconName.UserCircle}>{user.DisplayName}</MenuItem>
        </Box>
      </Menu>
      <Overlay hidden={!isOpen} onClick={() => setIsOpen(false)} />
    </>
  );
}

const MobileGlobalStyles = createGlobalStyle`
  html {
    height: calc(100% + env(safe-area-inset-top));
  }
  body {
    height: 100%;
    @media screen and (max-width: 768px) {
      position: fixed;
    }
  }

  input[type='color'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'],
  select:focus,
  textarea {
      font-size: 16px;
    }
`;
