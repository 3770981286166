import type { AnchorHTMLAttributes, ButtonHTMLAttributes, CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import styled, { css, type DefaultTheme } from 'styled-components';

import { parseDimension } from '../../styles';
import { FormControlSizes } from '../Form/types';
import { getFontSize, getLineHeight } from '../Form/utils';
import type { IconName } from '../Icons';
import { clippedButtonCss, getButtonBorderRadius } from './utils';

export enum ButtonVariants {
  Priority = 'Priority',
  Primary = 'Primary',
  Positive = 'Positive',
  Negative = 'Negative',
  Default = 'Default',
  /**
   * A button that can be used as an "off"-state for toggle buttons, for example in a button-group
   * with shared background color.
   */
  Muted = 'Muted',
}

export enum ButtonStates {
  Default = '',
  Hover = 'Hover',
  Focus = 'Focus',
  Active = 'Active',
  Disabled = 'Disabled',
}

export const InternalButtonSpan = styled.span`
  font-size: inherit;
  width: 100%;
`;

export const buttonStyle = (
  theme: DefaultTheme,
  size?: FormControlSizes,
  variant?: ButtonVariants,
  disabled?: boolean,
  ghost?: boolean,
  prominent?: boolean,
  dim?: boolean,
  width?: CSSProperties['width'],
  height?: CSSProperties['height'],
  justifyContent: CSSProperties['justifyContent'] = 'center',
  flex?: CSSProperties['flex']
) => css`
  ${flex &&
  css`
    &&& {
      flex: ${flex};
    }
  `}
  text-decoration: none; // Useful when using forwardedAs={Link}
  position: relative;
  padding: ${getButtonSpacing(theme, size)};
  font-weight: ${({ theme }) => theme.fontWeightMedium};
  border-radius: ${getButtonBorderRadius(theme, size)}px;
  font-size: ${getFontSize(theme, size)}rem;
  ${height && `min-height: ${height}px;`}
  width: ${width ? parseDimension(theme, width) : 'auto'};
  border: solid ${({ theme }) => theme.borderWidthButton}px;
  cursor: ${disabled ? 'not-allowed' : 'pointer'};
  display: inline-flex;
  gap: ${() => getIconSpacing(size)}px;
  align-items: center;
  justify-content: ${justifyContent};
  text-align: center;
  white-space: nowrap;
  transition: box-shadow 200ms ease, background 200ms ease, border 200ms ease, opacity 200ms ease;
  line-height: ${getLineHeight(theme, size)}rem;

  ${buttonColor(theme, variant, disabled, ghost, dim)}
  > ${InternalButtonSpan} {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex: 0 1;
  }

  ${prominent && theme.chamferMultiplier > 0 && clippedButtonCss({ size, theme })}
`;

const nonForwardedProps = new Set('size');
export const ButtonWrapper = styled.button
  .attrs<ButtonProps>(({ type = 'button' }) => ({ type }))
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => !nonForwardedProps.has(prop) && defaultValidatorFn(prop),
  })<ButtonProps>`
    ${({ theme, size, variant, disabled, ghost, prominent, dim, width, height, justifyContent, flex }) =>
      buttonStyle(theme, size, variant, disabled, ghost, prominent, dim, width, height, justifyContent, flex)}`;

export const NavButtonWrapper = styled(Link)<ButtonProps<AnchorHTMLAttributes<HTMLAnchorElement>>>`
  text-decoration: none;
  ${({ theme, size, variant, disabled, ghost, dim, prominent, width, height, justifyContent, flex }) =>
    buttonStyle(theme, size, variant, disabled, ghost, dim, prominent, width, height, justifyContent, flex)}
  text-decoration: none;
`;

const buttonColor = (
  theme: DefaultTheme,
  variant?: ButtonVariants,
  disabled?: boolean,
  ghost?: boolean,
  dim?: boolean
) => css`
  opacity: ${disabled ? 0.5 : 1};

  ${disabled
    ? getColors(theme, variant, ghost, dim, ButtonStates.Disabled)
    : `
  ${getColors(theme, variant, ghost, dim, ButtonStates.Default)}
  &:focus {
    ${getColors(theme, variant, ghost, dim, ButtonStates.Focus)}
    outline: none;
  }
  &:hover {
    ${getColors(theme, variant, ghost, dim, ButtonStates.Hover)}
  }
  &:active {
    ${getColors(theme, variant, ghost, dim, ButtonStates.Active)}
  }`}
`;

export type ButtonProps<T = ButtonHTMLAttributes<HTMLButtonElement>> = Omit<T, 'size' | 'height' | 'disabled'> & {
  disabled?: boolean;
  variant?: ButtonVariants;
  size?: FormControlSizes;
  startIcon?: IconName;
  endIcon?: IconName;
  ghost?: boolean;
  /**
   * If a button is prominent, it will have an altered apperance dependent on the theme.
   * For example, it can have chamfered edges, or be extended to have a different background color.
   */
  prominent?: boolean;
  dim?: boolean;
  done?: boolean;
  loading?: boolean;
  height?: CSSProperties['height'];
  width?: CSSProperties['width'];
  justifyContent?: CSSProperties['justifyContent'];
  flex?: CSSProperties['flex'];
};

export const getButtonVerticalSpacing = (theme: DefaultTheme, size = FormControlSizes.Default) => {
  switch (size) {
    case FormControlSizes.Xxs: {
      return theme.spacingTiny - theme.borderWidthButton;
    }
    case FormControlSizes.Tiny: {
      return theme.spacingSmall - theme.borderWidthButton;
    }
    case FormControlSizes.Small: {
      return theme.spacingSmall - theme.borderWidthButton;
    }
    case FormControlSizes.Default: {
      return theme.spacingDefault - theme.borderWidthButton;
    }
    case FormControlSizes.Large: {
      return theme.spacingMedium - theme.borderWidthButton;
    }
  }
};

export const getButtonHorizontalSpacing = (theme: DefaultTheme, size = FormControlSizes.Default) => {
  const horizontalSpacingTiny = theme.baseSize * 0.375 - theme.borderWidthButton;

  switch (size) {
    case FormControlSizes.Xxs: {
      return horizontalSpacingTiny - 1;
    }
    case FormControlSizes.Tiny: {
      return horizontalSpacingTiny;
    }
    case FormControlSizes.Small: {
      return theme.spacingDefault - theme.borderWidthButton;
    }
    case FormControlSizes.Default: {
      return theme.spacingComfortable - theme.borderWidthButton;
    }
    case FormControlSizes.Large: {
      return theme.spacingMedium - theme.borderWidthButton;
    }
  }
};

export const getButtonSpacing = (theme: DefaultTheme, size = FormControlSizes.Default) => {
  const verticalSpacing = getButtonVerticalSpacing(theme, size);
  const horizontalSpacing = getButtonHorizontalSpacing(theme, size);
  return `${verticalSpacing}px ${horizontalSpacing}px`;
};

export const getIconSpacing = (size = FormControlSizes.Default) => {
  switch (size) {
    case FormControlSizes.Xxs: {
      return 2;
    }
    case FormControlSizes.Tiny: {
      return 3;
    }
    case FormControlSizes.Small: {
      return 3;
    }
    case FormControlSizes.Default: {
      return 4;
    }
    case FormControlSizes.Large: {
      return 6;
    }
  }
};

export const getIconSize = (size = FormControlSizes.Default) => {
  switch (size) {
    case FormControlSizes.Tiny: {
      return 'fontSizeSmall';
    }
    case FormControlSizes.Small: {
      return 'fontSizeDefault';
    }
    case FormControlSizes.Default: {
      return 'fontSizeLarge';
    }
    case FormControlSizes.Large: {
      return 'fontSizeBig';
    }
  }
};

const getColors = (
  theme: DefaultTheme,
  variant = ButtonVariants.Default,
  ghost?: boolean,
  dim = false,
  state = ButtonStates.Default
) => {
  switch (variant) {
    case ButtonVariants.Primary: {
      switch (state) {
        case ButtonStates.Hover: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowPrimaryButtonHover : 'none'};
          border-color: ${!ghost ? theme.borderColorPrimaryButtonHover : 'transparent'};
          background: ${!ghost ? theme.backgroundPrimaryButtonHover : theme.colors.primary.dim};
          color: ${theme.colorTextPrimaryButtonHover};
          `;
        }
        case ButtonStates.Active: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowPrimaryButtonActive : 'none'};
          border-color: ${!ghost ? theme.borderColorPrimaryButtonActive : 'transparent'};
          background: ${!ghost ? theme.backgroundPrimaryButtonActive : 'transparent'};
          color: ${!ghost ? theme.colorTextPrimaryButtonActive : theme.colorTextImportant};
          `;
        }
        case ButtonStates.Focus: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowPrimaryButtonFocus : 'none'};
          border-color: ${!ghost ? theme.borderColorPrimaryButtonFocus : 'transparent'};
          background: ${!ghost ? theme.backgroundPrimaryButtonFocus : theme.colors.primary.dim};
          color: ${!ghost ? theme.colorTextPrimaryButtonFocus : theme.colorTextImportant};
          `;
        }
        case ButtonStates.Disabled: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowPrimaryButtonDisabled : 'none'};
          border-color: ${!ghost ? theme.borderColorPrimaryButtonDisabled : 'transparent'};
          background: ${!ghost ? theme.backgroundPrimaryButtonDisabled : 'transparent'};
          color: ${!ghost ? theme.colorTextPrimaryButtonDisabled : theme.colorTextSubtle};
          `;
        }
        default: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowPrimaryButton : 'none'};
          border-color: ${!ghost ? theme.borderColorPrimaryButton : 'transparent'};
          background: ${!ghost ? theme.backgroundPrimaryButton : 'transparent'};
          color: ${!ghost ? theme.colorTextPrimaryButton : theme.colorTextImportant};
          `;
        }
      }
    }
    case ButtonVariants.Positive: {
      switch (state) {
        case ButtonStates.Hover: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowPositiveButtonHover : 'none'};
          border-color: ${!ghost ? theme.borderColorPositiveButtonHover : 'transparent'};
          background: ${!ghost ? theme.backgroundPositiveButtonHover : theme.colors.green.dim};
          color: ${theme.colorTextPositiveButtonHover};
          `;
        }
        case ButtonStates.Active: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowPositiveButtonActive : 'none'};
          border-color: ${!ghost ? theme.borderColorPositiveButtonActive : 'transparent'};
          background: ${!ghost ? theme.backgroundPositiveButtonActive : 'transparent'};
          color: ${theme.colorTextPositiveButtonActive};
          `;
        }
        case ButtonStates.Focus: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowPositiveButtonFocus : 'none'};
          border-color: ${!ghost ? theme.borderColorPositiveButtonFocus : 'transparent'};
          background: ${!ghost ? theme.backgroundPositiveButtonFocus : theme.colors.green.dim};
          color: ${!ghost ? theme.colorTextPositiveButtonFocus : theme.colorTextImportant};
          `;
        }
        case ButtonStates.Disabled: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowPositiveButtonDisabled : 'none'};
          border-color: ${!ghost ? theme.borderColorPositiveButtonDisabled : 'transparent'};
          background: ${!ghost ? theme.backgroundPositiveButtonDisabled : 'transparent'};
          color: ${!ghost ? theme.colorTextPositiveButtonDisabled : theme.colorTextSubtle};
          `;
        }
        default: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowPositiveButton : 'none'};
          border-color: ${!ghost ? theme.borderColorPositiveButton : 'transparent'};
          background: ${!ghost ? theme.backgroundPositiveButton : 'transparent'};
          color: ${!ghost ? theme.colorTextPositiveButton : theme.colorTextImportant};
          `;
        }
      }
    }
    case ButtonVariants.Negative: {
      switch (state) {
        case ButtonStates.Hover: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowNegativeButtonHover : 'none'};
          border-color: ${!ghost ? theme.borderColorNegativeButtonHover : 'transparent'};
          background: ${!ghost ? theme.backgroundNegativeButtonHover : theme.colors.red.dim};
          color: ${theme.colorTextNegativeButtonHover};
          `;
        }
        case ButtonStates.Active: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowNegativeButtonActive : 'none'};
          border-color: ${!ghost ? theme.borderColorNegativeButtonActive : 'transparent'};
          background: ${!ghost ? theme.backgroundNegativeButtonActive : 'transparent'};
          color: ${theme.colorTextNegativeButtonActive};
          `;
        }
        case ButtonStates.Focus: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowNegativeButtonFocus : 'none'};
          border-color: ${!ghost ? theme.borderColorNegativeButtonFocus : 'transparent'};
          background: ${!ghost ? theme.backgroundNegativeButtonFocus : theme.colors.red.dim};
          color: ${!ghost ? theme.colorTextNegativeButtonFocus : theme.colorTextImportant};
          `;
        }
        case ButtonStates.Disabled: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowNegativeButtonDisabled : 'none'};
          border-color: ${!ghost ? theme.borderColorNegativeButtonDisabled : 'transparent'};
          background: ${!ghost ? theme.backgroundNegativeButtonDisabled : 'transparent'};
          color: ${!ghost ? theme.colorTextNegativeButtonDisabled : theme.colorTextSubtle};
          `;
        }
        default: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowNegativeButton : 'none'};
          border-color: ${!ghost ? theme.borderColorNegativeButton : 'transparent'};
          background: ${!ghost ? theme.backgroundNegativeButton : 'transparent'};
          color: ${!ghost ? theme.colorTextNegativeButton : theme.colorTextImportant};
          `;
        }
      }
    }
    case ButtonVariants.Priority: {
      switch (state) {
        case ButtonStates.Hover: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowPriorityButtonHover : 'none'};
          border-color: ${!ghost ? theme.borderColorPriorityButtonHover : 'transparent'};
          background: ${!ghost ? theme.backgroundPriorityButtonHover : theme.colors.white.mute};
          color: ${theme.colorTextPriorityButtonHover};
          `;
        }
        case ButtonStates.Active: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowPriorityButtonActive : 'none'};
          border-color: ${!ghost ? theme.borderColorPriorityButtonActive : 'transparent'};
          background: ${!ghost ? theme.backgroundPriorityButtonActive : 'transparent'};
          color: ${theme.colorTextPriorityButtonActive};
          `;
        }
        case ButtonStates.Focus: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowPriorityButtonFocus : 'none'};
          border-color: ${!ghost ? theme.borderColorPriorityButtonFocus : 'transparent'};
          background: ${!ghost ? theme.backgroundPriorityButtonFocus : theme.colors.white.mute};
          color: ${!ghost ? theme.colorTextPriorityButtonFocus : theme.colorTextImportant};
          `;
        }
        case ButtonStates.Disabled: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowPriorityButtonDisabled : 'none'};
          border-color: ${!ghost ? theme.borderColorPriorityButtonDisabled : 'transparent'};
          background: ${!ghost ? theme.backgroundPriorityButtonDisabled : 'transparent'};
          color: ${!ghost ? theme.colorTextPriorityButtonDisabled : theme.colorTextSubtle};
          `;
        }
        default: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowPriorityButton : 'none'};
          border-color: ${!ghost ? theme.borderColorPriorityButton : 'transparent'};
          background: ${!ghost ? theme.backgroundPriorityButton : 'transparent'};
          color: ${
            !ghost ? (!dim ? theme.colorTextPriorityButton : theme.colors.gray['090']) : theme.colorTextImportant
          };
          `;
        }
      }
    }
    case ButtonVariants.Muted: {
      switch (state) {
        case ButtonStates.Hover: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowMutedButtonHover : 'none'};
          border-color: ${!ghost ? theme.borderColorMutedButtonHover : 'transparent'};
          background: ${!ghost ? theme.backgroundMutedButtonHover : theme.colors.white.mute};
          color: ${theme.colorTextMutedButtonHover};
          `;
        }
        case ButtonStates.Active: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowMutedButtonActive : 'none'};
          border-color: ${!ghost ? theme.borderColorMutedButtonActive : 'transparent'};
          background: ${!ghost ? theme.backgroundMutedButtonActive : 'transparent'};
          color: ${theme.colorTextMutedButtonActive};
          `;
        }
        case ButtonStates.Focus: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowMutedButtonFocus : 'none'};
          border-color: ${!ghost ? theme.borderColorMutedButtonFocus : 'transparent'};
          background: ${!ghost ? theme.backgroundMutedButtonFocus : theme.colors.white.mute};
          color: ${!ghost ? theme.colorTextMutedButtonFocus : theme.colorTextImportant};
          `;
        }
        case ButtonStates.Disabled: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowMutedButtonDisabled : 'none'};
          border-color: ${!ghost ? theme.borderColorMutedButtonDisabled : 'transparent'};
          background: ${!ghost ? theme.backgroundMutedButtonDisabled : 'transparent'};
          color: ${!ghost ? theme.colorTextMutedButtonDisabled : theme.colorTextImportant};
          `;
        }
        default: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowMutedButton : 'none'};
          border-color: ${!ghost ? theme.borderColorMutedButton : 'transparent'};
          background: ${!ghost ? theme.backgroundMutedButton : 'transparent'};
          color: ${!ghost ? (!dim ? theme.colorTextMutedButton : theme.colors.gray['090']) : theme.colorTextImportant};
          `;
        }
      }
    }
    case ButtonVariants.Default:
    default: {
      switch (state) {
        case ButtonStates.Hover: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowDefaultButtonHover : 'none'};
          border-color: ${!ghost ? theme.borderColorDefaultButtonHover : 'transparent'};
          background: ${!ghost ? theme.backgroundDefaultButtonHover : theme.colors.white.mute};
          color: ${theme.colorTextDefaultButtonHover};
          `;
        }
        case ButtonStates.Active: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowDefaultButtonActive : 'none'};
          border-color: ${!ghost ? theme.borderColorDefaultButtonActive : 'transparent'};
          background: ${!ghost ? theme.backgroundDefaultButtonActive : 'transparent'};
          color: ${theme.colorTextDefaultButtonActive};
          `;
        }
        case ButtonStates.Focus: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowDefaultButtonFocus : 'none'};
          border-color: ${!ghost ? theme.borderColorDefaultButtonFocus : 'transparent'};
          background: ${!ghost ? theme.backgroundDefaultButtonFocus : theme.colors.white.mute};
          color: ${!ghost ? theme.colorTextDefaultButtonFocus : theme.colorTextImportant};
          `;
        }
        case ButtonStates.Disabled: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowDefaultButtonDisabled : 'none'};
          border-color: ${!ghost ? theme.borderColorDefaultButtonDisabled : 'transparent'};
          background: ${!ghost ? theme.backgroundDefaultButtonDisabled : 'transparent'};
          color: ${!ghost ? theme.colorTextDefaultButtonDisabled : theme.colorTextImportant};
          `;
        }
        default: {
          return `
          box-shadow: ${!ghost ? theme.boxShadowDefaultButton : 'none'};
          border-color: ${!ghost ? theme.borderColorDefaultButton : 'transparent'};
          background: ${!ghost ? theme.backgroundDefaultButton : 'transparent'};
          color: ${
            !ghost ? (!dim ? theme.colorTextDefaultButton : theme.colors.gray['090']) : theme.colorTextImportant
          };
          `;
        }
      }
    }
  }
};

export function getIconButtonSize(theme: DefaultTheme, size: FormControlSizes) {
  return getButtonVerticalSpacing(theme, size) * 2 + getLineHeight(theme, size) * theme.baseSize;
}
