import { IconButton, IconName } from '@talos/kyoko';
import type { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';
import { CreditUtilization } from '../../../CreditUtilization';
import { Logo } from '../../../Logo';
import { HeaderContent, MobileHeaderWrapper, Section } from './styles';

export function MobileHeader({ onMenuClick }: { onMenuClick: Dispatch<SetStateAction<boolean>> }) {
  const { type } = useTheme();
  const history = useHistory();

  return (
    <MobileHeaderWrapper>
      <HeaderContent>
        <Section mr="auto" zIndex={1}>
          <IconButton onClick={() => onMenuClick(prev => !prev)} icon={IconName.Menu} ghost />
        </Section>
        <Section position="absolute" left="30%" right="30%" onClick={() => history.push('/')}>
          <Logo themeType={type} width="100%" />
        </Section>
        <Section ml="auto" zIndex={1}>
          <CreditUtilization />
        </Section>
      </HeaderContent>
    </MobileHeaderWrapper>
  );
}
