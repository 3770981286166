import styled from 'styled-components';
import { IconButton } from '../Button';
import { FormControlSizes } from '../Form/types';
import { IconName } from '../Icons';

export const DrawerCloseButton = styled(({ onClick, ...props }: { onClick: () => void }) => {
  return (
    <IconButton
      data-testid="drawer-close-button"
      ghost={true}
      icon={IconName.Close}
      size={FormControlSizes.Small}
      onClick={onClick}
      {...props}
    />
  );
})`
  position: absolute;
  top: ${({ theme }) => theme.spacingMedium}px;
  right: ${({ theme }) => theme.spacingMedium}px;
`;
