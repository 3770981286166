import type { FocusEvent, MouseEvent, RefObject } from 'react';
import { defineMessages } from 'react-intl';
import { useTheme } from 'styled-components';
import { ButtonVariants } from '../../../Button';
import { Flex } from '../../../Core';
import { IconName } from '../../../Icons';
import { FormattedMessage } from '../../../Intl';
import { SelectionButton, SelectionButtonWrapper, SelectionRemoveIcon, SelectionsListWrapper } from '../styles';
import type { PropertyRefs } from '../types';

const messages = defineMessages({
  select: {
    defaultMessage: 'Select...',
    id: 'Filters.FilterBuilder.RHS.select',
  },
});

type SelectionListButtonsProps = {
  visibleSelections: string[];
  refs: PropertyRefs;
  onOpenClick: (
    e: MouseEvent<HTMLButtonElement> | FocusEvent<HTMLButtonElement>,
    ref: RefObject<HTMLButtonElement>
  ) => void;
  onRemovePropertyClick: () => void;
  onRemoveSelectionClick: (e: MouseEvent<HTMLElement>, selection: string) => void;
  tailLength?: number;
  isSelectionDisabled?: (selection: string) => boolean;
  getOptionLabel: (selection: string) => string;
  /** Whether or not to show the Remove (X) button for the entire Property, defaults to true */
  entirePropertyRemovable?: boolean;
};

/**
 * This component encapsulates the default visual representation of the RHS filter clause selections
 */
export const SelectionListButtons = ({
  visibleSelections,
  refs,
  onOpenClick,
  onRemovePropertyClick,
  onRemoveSelectionClick,
  isSelectionDisabled,
  tailLength,
  getOptionLabel,
  entirePropertyRemovable = true,
}: SelectionListButtonsProps) => {
  const { colors } = useTheme();

  return (
    <SelectionsListWrapper>
      {visibleSelections.length === 0 ? (
        <SelectionButtonWrapper>
          <SelectionButton
            id="no-selections"
            variant={ButtonVariants.Default}
            onClick={e => onOpenClick(e, refs.empty)}
            ref={refs.empty}
          >
            <Flex gap="spacingSmall" alignItems="center">
              <span>
                <FormattedMessage {...messages.select} />
              </span>
              {entirePropertyRemovable && (
                <SelectionRemoveIcon
                  icon={IconName.Close}
                  onClick={onRemovePropertyClick}
                  style={{ pointerEvents: 'auto' }}
                  data-testid="selection-remove-icon"
                  tabIndex={-1}
                />
              )}
            </Flex>
          </SelectionButton>
        </SelectionButtonWrapper>
      ) : (
        <>
          {visibleSelections.map(selection => {
            const ref = refs.selections.get(selection)!;
            // Recall that visibleSelections array is based on the "latest confirmed" + "temporary additions" selections.
            // Some of them might be pending removal.
            const isDisabled = isSelectionDisabled?.(selection);
            return (
              <SelectionButtonWrapper key={selection}>
                <SelectionButton
                  variant={ButtonVariants.Default}
                  onClick={e => onOpenClick(e, ref)}
                  disabled={isDisabled}
                  ref={ref}
                  data-testid="filter-builder-clause-selection"
                >
                  <Flex gap="spacingSmall" alignItems="center">
                    <span>{getOptionLabel(selection)}</span>
                    <SelectionRemoveIcon
                      icon={IconName.Close}
                      onClick={e => !isDisabled && onRemoveSelectionClick(e, selection)}
                      style={{ pointerEvents: 'auto' }}
                      data-testid="selection-remove-icon"
                      tabIndex={-1}
                    />
                  </Flex>
                </SelectionButton>
              </SelectionButtonWrapper>
            );
          })}
          {tailLength != null && tailLength > 0 && (
            <SelectionButtonWrapper>
              <SelectionButton
                variant={ButtonVariants.Default}
                onClick={e => onOpenClick(e, refs.tail)}
                style={{ color: colors.gray['090'] }}
                ref={refs.tail}
              >
                +{tailLength}
              </SelectionButton>
            </SelectionButtonWrapper>
          )}
        </>
      )}
    </SelectionsListWrapper>
  );
};
