import type { BigSource } from 'big.js';
import type { ExecutionReport } from '../types/ExecutionReport';
import type { Order } from '../types/Order';
import type { Security } from '../types/Security';
import { SettleValueTypeEnum } from '../types/types';
import { isOrderComplete } from './isOrderComplete';
import type { AmountObject } from './notional.types';
import { toBigWithDefault } from './number';
import { isCounterCurrency } from './order';
import { isSecurityQuantoFuture } from './security';

export type { AmountObject } from './notional.types';

type Entity = ExecutionReport | Order;

export function getFilledNotional(
  entity: Pick<Entity, 'CumQty' | 'CumAmt' | 'Currency' | 'AmountCurrency'> | undefined,
  security: Security | undefined
): AmountObject {
  if (!security || !entity) {
    return { currency: '', value: '' };
  }

  // Contracts
  if (!entity.Currency) {
    // We need to figure out what currency is dealt and what is counter, so we can map the amounts to the appropriate columns.
    if (entity.AmountCurrency === security.QuoteCurrency) {
      return {
        currency: entity.AmountCurrency,
        value: toBigWithDefault(entity.CumAmt, 0).toFixed(),
      };
    } else {
      return {
        currency: security.QuoteCurrency,
        value: toBigWithDefault(entity.CumQty, 0).times(toBigWithDefault(security.NotionalMultiplier, 1)).toFixed(),
      };
    }
  }

  const isCcy = isCounterCurrency(entity.Currency, security);

  if (isCcy) {
    return {
      currency: entity.Currency,
      value: toBigWithDefault(entity.CumQty, 0).toFixed(),
    };
  } else {
    return {
      currency: entity.AmountCurrency ?? '',
      value: toBigWithDefault(entity.CumAmt, 0).toFixed(),
    };
  }
}

export function getOpenNotional(
  entity:
    | Pick<Entity, 'OrderQty' | 'CumQty' | 'CumAmt' | 'Currency' | 'AmountCurrency' | 'ExpectedFillPrice' | 'OrdStatus'>
    | undefined,
  security: Security | undefined
): AmountObject {
  if (!security || !entity) {
    return { currency: '', value: '' };
  }

  if (isOrderComplete(entity.OrdStatus)) {
    return {
      currency: security.QuoteCurrency,
      value: '0',
    };
  }

  const totalNotional = getTotalNotional(entity, security);
  const filledNotional = getFilledNotional(entity, security);

  return {
    currency: security.QuoteCurrency,
    // Open Notional will not be calculated if Total Notional is not calculated
    value: toBigWithDefault(totalNotional.value, 0).gt(0)
      ? toBigWithDefault(totalNotional.value, 0)
          .minus(filledNotional.value || 0)
          .toFixed()
      : '',
  };
}

export function getTotalNotional(
  entity:
    | Pick<Entity, 'OrderQty' | 'CumQty' | 'ExpectedFillPrice' | 'Price' | 'Currency' | 'AmountCurrency'>
    | undefined,
  security: Security | undefined
): AmountObject {
  if (!security || !entity) {
    return { currency: '', value: '' };
  }

  // For an API entered order, ExpectedFillPrice will not be present. In this case, we use the Limit Px.
  // If Limit Px is also not present e.g. a market strategy entered over API, then Total Notional (and as a result Open Notional) will not be calculated.
  const expectedFillPriceBig = toBigWithDefault(entity.ExpectedFillPrice, 0);
  const priceBig = expectedFillPriceBig.eq(0) ? toBigWithDefault(entity.Price, 0) : expectedFillPriceBig;

  // Contracts
  if (!entity.Currency) {
    if (entity.AmountCurrency === security.QuoteCurrency) {
      if (priceBig.eq(0)) {
        return { currency: '', value: '' };
      }
      return {
        currency: security.QuoteCurrency,
        value: toBigWithDefault(entity.OrderQty, 0)
          .times(priceBig)
          .times(toBigWithDefault(security.NotionalMultiplier, 1))
          .toFixed(),
      };
    } else {
      return {
        currency: security.QuoteCurrency,
        value: toBigWithDefault(entity.OrderQty, 0).times(toBigWithDefault(security.NotionalMultiplier, 1)).toFixed(),
      };
    }
  }

  const isCcy = isCounterCurrency(entity.Currency, security);

  if (isCcy) {
    return {
      currency: security.QuoteCurrency,
      value: toBigWithDefault(entity.OrderQty, 0).toFixed(),
    };
  } else {
    if (priceBig.eq(0)) {
      return { currency: '', value: '' };
    }
    return {
      currency: security.QuoteCurrency,
      value: toBigWithDefault(entity.OrderQty, 0).times(priceBig).toFixed(),
    };
  }
}

export function getFilledBaseQuantity(
  entity: Pick<Entity, 'CumQty' | 'CumAmt' | 'Currency' | 'AmountCurrency'> | undefined,
  security: Security | undefined
): AmountObject {
  if (!security || !entity) {
    return { currency: '', value: '' };
  }

  // Contracts
  if (!entity.Currency) {
    if (entity.AmountCurrency === security.QuoteCurrency) {
      return {
        currency: security.BaseCurrency,
        value: toBigWithDefault(entity.CumQty, 0).times(toBigWithDefault(security.NotionalMultiplier, 1)).toFixed(),
      };
    } else {
      return {
        currency: entity.AmountCurrency ?? '',
        value: toBigWithDefault(entity.CumAmt, 0).toFixed(),
      };
    }
  }

  const isCcy = isCounterCurrency(entity.Currency, security);
  if (isCcy) {
    return {
      currency: entity.AmountCurrency ?? '',
      value: toBigWithDefault(entity.CumAmt, 0).toFixed(),
    };
  } else {
    return {
      currency: entity.Currency ?? '',
      value: toBigWithDefault(entity.CumQty, 0).toFixed(),
    };
  }
}

export function getFilledCounterAmount(
  entity: Pick<Entity, 'CumAmt' | 'AmountCurrency'> | undefined,
  security: Security | undefined
): AmountObject {
  if (!security || !entity) {
    return { currency: '', value: '' };
  }
  return {
    currency: entity.AmountCurrency ?? '',
    value: toBigWithDefault(entity.CumAmt, 0).toFixed(),
  };
}

export function getPositionAmount(quantity: BigSource, security: Security | undefined): AmountObject {
  if (!security) {
    return { currency: '', value: '' };
  }
  if (isSecurityQuantoFuture(security)) {
    return { currency: '', value: toBigWithDefault(quantity, 0).toFixed() };
  }
  const value = toBigWithDefault(quantity, 0).mul(toBigWithDefault(security.NotionalMultiplier, 1)).toFixed();
  const currency =
    security.SettleValueType === SettleValueTypeEnum.Inverted ? security.QuoteCurrency : security.BaseCurrency;
  return { currency, value };
}

export function getCumAmt(
  quantity: BigSource,
  price: BigSource,
  orderCurrency: string,
  security: Security | undefined
): AmountObject {
  const priceBig = toBigWithDefault(price, 0);
  if (!security || priceBig.eq(0)) {
    return {
      currency: '',
      value: '0',
    };
  }

  const cumQtyBig = toBigWithDefault(quantity, 0);
  if (!orderCurrency) {
    // Contracts
    const notionalMultiplierBig = toBigWithDefault(security?.NotionalMultiplier, 1);
    if (security?.SettleValueType === SettleValueTypeEnum.Inverted) {
      return {
        currency: security.BaseCurrency,
        value: cumQtyBig.times(notionalMultiplierBig).div(priceBig).toFixed(),
      };
    } else {
      return {
        currency: security?.QuoteCurrency,
        value: cumQtyBig.times(notionalMultiplierBig).times(priceBig).toFixed(),
      };
    }
  } else {
    const isCcy = isCounterCurrency(orderCurrency, security);
    if (isCcy) {
      return {
        currency: security?.BaseCurrency,
        value: cumQtyBig.div(priceBig).toFixed(),
      };
    } else {
      return {
        currency: security.QuoteCurrency,
        value: cumQtyBig.times(priceBig).toFixed(),
      };
    }
  }
}
