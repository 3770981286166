import {
  Box,
  Flex,
  MixpanelEvent,
  MixpanelEventSource,
  SymbolSelector,
  SymbolSelectorAppearance,
  useDynamicCallback,
  useMixpanel,
  useScreenOrientation,
} from '@talos/kyoko';
import { useOMSStateContext, useOMSUtilsContext } from '../../providers';
import { Candlesticks } from './';

export const CandleSticksWithSymbol = () => {
  const { symbol } = useOMSStateContext();
  const { setSymbol } = useOMSUtilsContext();
  const mixpanel = useMixpanel();

  const handleSymbolChange = useDynamicCallback((symbol?: string) => {
    if (symbol) {
      setSymbol(symbol);
      mixpanel.track(MixpanelEvent.ChangeSymbol, { source: MixpanelEventSource.CandleSticks });
    }
  });

  const orientation = useScreenOrientation();
  const isPortrait = orientation === 'portrait-primary' || orientation === 'portrait-secondary';

  return (
    <Flex flexDirection="column" h="100%">
      {isPortrait && (
        <Flex h="4rem" p="spacingMedium" borderBottom="solid 2px" borderColor="backgroundBody">
          <SymbolSelector
            appearance={SymbolSelectorAppearance.Ghost}
            symbol={symbol}
            onSymbolChanged={handleSymbolChange}
            dropdownWidth="100%"
          />
        </Flex>
      )}
      <Box flex="1">
        <Candlesticks />
      </Box>
    </Flex>
  );
};
