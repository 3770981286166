import { HStack, Icon } from '@talos/kyoko';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const MobileTabBarWrapper = styled(HStack)`
  padding-bottom: env(safe-area-inset-bottom, 16px);
`;

export const TabBarLink = styled(NavLink)`
  text-decoration: none;

  &.active {
    > div {
      border-color: ${({ theme }) => theme.colorTextImportant};
      color: ${({ theme }) => theme.colorTextImportant};
    }

    ${Icon} {
      color: ${({ theme }) => theme.colorTextImportant};
    }
  }
`;
