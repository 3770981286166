import type { ICellRendererParams, IRowNode, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import type Big from 'big.js';
import { get } from 'lodash';
import type { BuyingPower, SideEnum } from '../../../types';
import { toBigWithDefault } from '../../../utils';
import { HStack, VStack } from '../../Core';
import { InlineFormattedNumber, NumberVariants } from '../../FormattedNumber';
import { Text } from '../../Text';
import { baseColumn } from '../columns/baseColumn';
import type { ColDefFactory, Column } from '../columns/types';

export interface BuyingPowerColumnParams<T = any> {
  /** The side (Buy or Sell) this column refers to. Required. */
  side: SideEnum;
  /** Whether or not to use BuyingPower.MaxBuy/SellOrderSize when getting the effective Buying Power */
  useMaxOrderSize?: boolean;
  /** The quantity to compare the BuyingPower to. Optional. In the future, this column can be extended to also support a `quantityField` parameter.  */
  quantity?: string;
  currency: string;
  /**
   * A callback for you to tell the column when it is allowed to show a warning when the quantity is in
   * excess of the Buying Power. If not provided, the column will always show a warning when in excess.
   *
   * For example, this callback can be used to limit visible warnings to only selected rows.
   */
  showExcessWarning?: (node: IRowNode<T>) => boolean;
}

interface BuyingPowerColumnValue {
  /** The result of `showExcessWarning`. If false, will not show a warning despite the buying power being exceeded. */
  allowedToShowWarning: boolean;
  /** The buying power max available. Defaults to 0 if not found. */
  max: Big;
  /** The Order Qty. Used to compare against and render a warning if this Qty exceeds the resolved BuyingPower */
  quantity: Big;
  /** The currency of the BuyingPower */
  currency: string;
  /** The side of the Order (Buy/Sell) */
  side: SideEnum;
}

export const buyingPower: ColDefFactory<Column<BuyingPowerColumnParams>> = column => ({
  ...baseColumn(column),
  type: 'numericColumn',
  headerClass: 'ag-right-aligned-header',
  valueGetter: ({ data, node }: ValueGetterParams<any>): BuyingPowerColumnValue | undefined => {
    const field = column.field;
    const side = column.params?.side;
    const currency = column.params?.currency;
    if (!field || !data || !node || !side || !currency) {
      return undefined;
    }

    const buyingPower: BuyingPower | undefined = get(data, field);

    const useMaxOrderSize = column.params?.useMaxOrderSize ?? false;
    const max = toBigWithDefault(buyingPower?.getMaxSize({ useMaxOrderSize, side }), 0);

    const allowedToShowWarning = column.params?.showExcessWarning?.(node) ?? true;

    return {
      max,
      allowedToShowWarning,
      quantity: toBigWithDefault(column.params?.quantity, 0),
      currency,
      side,
    };
  },
  valueFormatter: ({ value }: ValueFormatterParams<unknown, BuyingPowerColumnValue>) => {
    if (!value) {
      return '';
    }

    return value.max.toFixed();
  },
  cellRenderer: ({ value }: ICellRendererParams<unknown, BuyingPowerColumnValue>) => {
    if (!value) {
      return null;
    }

    const showExcessWarning = value.allowedToShowWarning && value.quantity.gt(value.max);
    const excess = value.quantity.minus(value.max);

    return (
      <VStack gap="spacingTiny" alignItems="flex-end">
        <InlineFormattedNumber number={value.max} currency={value.currency} align="right" />
        {showExcessWarning && (
          <HStack gap="spacingTiny">
            <Text color="colorTextWarning">Exceeds:</Text>
            <InlineFormattedNumber
              number={excess.toFixed()}
              currency={value.currency}
              variant={NumberVariants.Warning}
              align="right"
            />
          </HStack>
        )}
      </VStack>
    );
  },
});
