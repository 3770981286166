import { ResizerPlacement } from '@talos/kyoko';
import {
  useCallback,
  useEffect,
  useState,
  type MouseEvent,
  type MouseEventHandler,
  type PropsWithChildren,
  type ReactElement,
  type TouchEvent,
  type TouchEventHandler,
} from 'react';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import type { LayoutPart } from '../../layouts/types';
import { Securities } from '../Securities';
import { HorizontalHandle, MouseEventCapture, PartContent, PartWrapper, VerticalHandle } from './styles';

const DEFAULT_ORDER_FORM_WIDTH = 384;

export const Part = ({ children, onMouseDown, resizing, collapsed, animate, borderRadius, ...part }: PartProps) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  const handleMouseDown: MouseEventHandler = useCallback(
    e => {
      onMouseDown(e, part);
    },
    [onMouseDown, part]
  );

  const handleTouchStart: TouchEventHandler = useCallback(
    e => {
      onMouseDown(e, part);
    },
    [onMouseDown, part]
  );

  const noAppearance = children && (children as ReactElement).type === Securities;

  return (
    <PartWrapper
      width={
        (!mounted || collapsed) && animate
          ? 0
          : part.isOrderForms
          ? part.maxWidth ?? DEFAULT_ORDER_FORM_WIDTH
          : undefined
      }
      border={collapsed || noAppearance ? 'none' : undefined}
      mr={collapsed ? '-spacingLayout' : undefined}
      background={noAppearance ? 'transparent' : undefined}
      boxShadow={noAppearance ? 'none' : undefined}
      backdropFilter={noAppearance ? 'none' : undefined}
      borderRadius={borderRadius}
      {...part}
    >
      {(part.resizable === ResizerPlacement.TOP || part.resizable === ResizerPlacement.BOTTOM) && (
        <VerticalHandle placement={part.resizable} onMouseDown={handleMouseDown} onTouchStart={handleTouchStart}>
          <div />
        </VerticalHandle>
      )}
      {(part.resizable === ResizerPlacement.LEFT || part.resizable === ResizerPlacement.RIGHT) && (
        <HorizontalHandle placement={part.resizable} onMouseDown={handleMouseDown} onTouchStart={handleTouchStart}>
          <div />
        </HorizontalHandle>
      )}
      {resizing && <MouseEventCapture />}
      <PartContent borderRadius={borderRadius}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </PartContent>
    </PartWrapper>
  );
};

type PartProps = PropsWithChildren<
  {
    resizing: boolean;
    onMouseDown: (e: MouseEvent | TouchEvent, part: LayoutPart) => void;
    collapsed?: boolean;
    animate?: boolean;
  } & Omit<LayoutPart, 'onMouseDown'>
>;
