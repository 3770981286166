import { noop } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export function useScreenOrientation() {
  const screenOrientation = useMemo(
    () => window.screen.orientation ?? { type: 'portrait-primary', addEventListener: noop, removeEventListener: noop },
    []
  );
  const [orientation, setOrientation] = useState(screenOrientation?.type);

  useEffect(() => {
    const handler = () => {
      setOrientation(screenOrientation?.type);
    };
    screenOrientation?.addEventListener('change', handler);
    return () => {
      screenOrientation?.removeEventListener('change', handler);
    };
  }, [screenOrientation]);

  return orientation;
}
