import { CustomerUserConfigLayoutNavigationTypes, CustomerUserConfigLayoutType, ResizerPlacement } from '@talos/kyoko';
import { Blotters } from '../components/Blotters/Blotters';
import { Candlesticks } from '../components/Candlesticks';
import { OrderForms } from '../components/OrderForms';
import { WatchList } from '../components/WatchList';
import { BLOTTERS_MIN_HEIGHT, MAIN_HEADER_HEIGHT, VOLUME_LADDER_HEIGHT } from '../styles/dimensions';
import { tradingMobileScreens } from './mobileScreens';
import type { LayoutComponent } from './types';

// Re-export these under their original names for convenience, until all consumers can be redirected to @talos/kyoko
export { CustomerUserConfigLayoutType as LayoutType, CustomerUserConfigLayoutNavigationTypes as NavigationTypes };

export const WatchListTradingLayout: LayoutComponent = {
  headerSymbolSelector: false,
  columns: ['minmax(16rem,26rem)', 'minmax(16rem,1fr)', 'auto'],
  rows: [`minmax(${VOLUME_LADDER_HEIGHT}px,1fr)`, 'minmax(0px,1fr)', `minmax(${BLOTTERS_MIN_HEIGHT}px,1fr)`],
  parts: [
    {
      column: '1',
      row: '1/3',
      component: <WatchList />,
      resizable: ResizerPlacement.RIGHT,
    },
    {
      column: '2',
      row: '1/3',
      component: <Candlesticks />,
    },
    {
      column: '1/3',
      row: '3',
      component: <Blotters />,
      minHeight: BLOTTERS_MIN_HEIGHT,
      maxHeight: window.innerHeight - MAIN_HEADER_HEIGHT - VOLUME_LADDER_HEIGHT - 20,
      resizable: ResizerPlacement.TOP,
    },
    {
      column: '3',
      row: '1/4',
      component: <OrderForms />,
      resizable: ResizerPlacement.LEFT,
      isOrderForms: true,
    },
  ],
  mobileScreens: tradingMobileScreens,
};
