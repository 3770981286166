import {
  OrderFormSides,
  WLParameterKeysEnum,
  format,
  isCounterCurrency,
  parseNumber,
  prettyName,
  useCurrencyConversionRateValue,
  useIntl,
  useWLTradingLimits,
  type FormDisabledState,
  type FormState,
  type FormValidationState,
  type Security,
  type WLOrderStrategy,
  type WLOrderStrategyParameter,
} from '@talos/kyoko';
import Big from 'big.js';
import { get, isEqual, toNumber } from 'lodash';
import { useEffect, useState } from 'react';
import { defineMessages, type IntlShape } from 'react-intl';
import {
  ValidationError,
  date,
  mixed,
  number,
  object,
  string,
  type DateSchema,
  type NumberSchema,
  type ObjectSchema,
  type StringSchema,
} from 'yup';
import type { MixedSchema } from 'yup/lib/mixed';
import type { AnyObject } from 'yup/lib/types';
import { useOrderEndTime, useOrderStartTime } from '../../hooks';
import type { FormViews } from '../../providers';
import { ORDER_FORM, RFQ_FORM } from '../../providers';

const messages = defineMessages({
  quantityShouldBeANumber: {
    id: 'OrderForms.quantityShouldBeANumber',
    defaultMessage: 'Quantity should be a number',
  },
  quantityIsRequired: {
    id: 'OrderForms.quantityIsRequired',
    defaultMessage: 'Quantity is required',
  },
  minQuantityIsMinSizeCurrency: {
    id: 'OrderForms.minQuantityIsMinSizeCurrency',
    defaultMessage: 'Min quantity is {minSize} {currency}',
  },
  minIncrementIsMinIncr: {
    id: 'OrderForms.minIncrementIsMinIncr',
    defaultMessage: 'Min increment is {minIncr} {currency}',
  },
  quantityExceedsRejectionThreshold: {
    id: 'OrderForms.quantityExceedsRejectionThreshold',
    defaultMessage: 'Quantity exceeds rejection threshold {threshold} {currency}',
  },
  pleaseSelectAnOrderSide: {
    id: 'OrderForms.pleaseSelectAnOrderSide',
    defaultMessage: 'Please select an order side',
  },
  marketAccountIsRequired: {
    id: 'OrderForms.marketAccountIsRequired',
    defaultMessage: 'Market Account is required',
  },
  displayNameShouldBeANumber: {
    id: 'OrderForms.displayNameShouldBeANumber',
    defaultMessage: '{displayName} should be a number',
  },
  displayNameIsRequired: {
    id: 'OrderForms.displayNameIsRequired',
    defaultMessage: '{displayName} is required',
  },
  displayNameShouldBeGreaterThanZero: {
    id: 'OrderForms.displayNameShouldBeGreaterThanZero',
    defaultMessage: '{displayName} should be greater than 0',
  },
  dateCannotBeInThePast: {
    id: 'OrderForms.dateCannotBeInThePast',
    defaultMessage: 'Date cannot be in the past',
  },
  displayNameCannotBeAfter: {
    id: 'OrderForms.displayNameCannotBeAfter',
    defaultMessage: "{displayName} can't be after {prettyName}",
  },
  displayNameCannotBeBefore: {
    id: 'OrderForms.displayNameCannotBeBefore',
    defaultMessage: "{displayName} can't be before {prettyName}",
  },
  displayNameShouldBeADate: {
    id: 'OrderForms.displayNameShouldBeADate',
    defaultMessage: '{displayName} should be a date',
  },
  invalidPercentageValue: {
    id: 'OrderForms.invalidPercentageValue',
    defaultMessage: 'Invalid percentage value',
  },
  displayNameMustBeAValidInterval: {
    id: 'OrderForms.displayNameMustBeAValidInterval',
    defaultMessage: '{displayName} must be a valid interval',
  },
});

export const useLegacyFormValidation = (
  view: FormViews,
  form: FormState,
  strategies: WLOrderStrategy[],
  security?: Security,
  fieldsDisabled?: FormDisabledState
) => {
  const { reject, limit } = useWLTradingLimits({
    quantity: form.OrderQty,
    currency: form.Currency,
    symbol: security?.Symbol,
    marketAccountName: form.MarketAccount,
  });
  const orderStartTime = useOrderStartTime(form.Parameters);
  const orderEndTime = useOrderEndTime(form.Parameters);
  const { BaseCurrency, QuoteCurrency } = security ?? {};
  const ccConversionRate = useCurrencyConversionRateValue(BaseCurrency, QuoteCurrency);
  const [errors, setErrors] = useState<FormValidationState>({});
  const { formatMessage } = useIntl();
  useEffect(() => {
    if (security != null) {
      // If this is a counter currency order we must still be able to validate the minimum allowed value.
      // `security.minimumSize` is given in the base currency, and there's currently no corresponding property for the counter currency.
      // When dealing with a counter-currency, we want to first find the conversion rate of the BaseCurrency (above),
      // and then multiply that conversion rate by the MinimumSize. This gives us the minimum amount in units of
      // QuoteCurrency at this point in time. Then, we need to round UP to the nearest precision of MinAmt or MinPrice increment.
      const { MinPriceIncrement, MinimumSize, MinAmtIncrement, MinSizeIncrement } = security;
      let minSize = MinimumSize;
      if (
        isCounterCurrency(form.Currency, security) &&
        ccConversionRate?.Rate != null &&
        ccConversionRate.EquivalentCurrency === QuoteCurrency &&
        ccConversionRate.Asset === BaseCurrency
      ) {
        const [_, precision = ''] = (MinAmtIncrement || MinPriceIncrement)?.split('.') ?? [];
        minSize = Big(ccConversionRate.Rate)
          .times(MinimumSize)
          .round(precision.length, 3 /* BigJS ROUND_UP */)
          .toFixed(precision.length);
      }
      // If this is a counter currency order, use the MinAmtIncrement if it's provided, otherwise, fall
      // back to the MinPriceIncrement field. If it's not counter currency, use MinSizeIncrement.
      const minIncr = isCounterCurrency(form.Currency, security)
        ? MinAmtIncrement || MinPriceIncrement
        : MinSizeIncrement;

      const validSides = [OrderFormSides.Buy, OrderFormSides.Sell];
      if (view === RFQ_FORM) {
        validSides.push(OrderFormSides.Twoway);
      }
      const rejectionLimit = reject ? limit : undefined;
      const schema: ValidationSchema = {
        OrderQty: number()
          .transform((_, value) => parseNumber(value))
          .nullable()
          .required(formatMessage(messages.quantityIsRequired))
          .typeError(formatMessage(messages.quantityShouldBeANumber))
          .min(
            toNumber(minSize),
            formatMessage(messages.minQuantityIsMinSizeCurrency, { minSize, currency: form.Currency })
          )
          .test('precision', formatMessage(messages.minIncrementIsMinIncr, { minIncr, currency: form.Currency }), q =>
            validatePrecision(minIncr, q)
          )
          .test(
            'maxOrderSize',
            formatMessage(messages.quantityExceedsRejectionThreshold, {
              threshold: format(rejectionLimit?.RejectThreshold),
              currency: rejectionLimit?.ThresholdCurrency,
            }),
            () => rejectionLimit == null
          ),
        Side: string().oneOf(validSides, formatMessage(messages.pleaseSelectAnOrderSide)),
        MarketAccount: string().required(formatMessage(messages.marketAccountIsRequired)),
      };

      if (view === ORDER_FORM) {
        const selectedStrategy = strategies.find(s => s.Name === form.Strategy);
        if (selectedStrategy?.Parameters) {
          const parametersSchema = selectedStrategy.Parameters.reduce((result, param) => {
            result[param.Name] = getParameterValidationRule({
              formatMessage,
              param,
              security,
              parameterMetadata: { orderStartTime, orderEndTime },
              fieldsDisabled,
            });
            return result;
          }, {});
          schema.Parameters = object().shape(parametersSchema);
        }
      }

      // Run validation
      setErrors(prev => {
        const validationSchema = object().shape(schema);
        const next = runValidation(validationSchema, form);
        if (isEqual(prev, next)) {
          return prev;
        }
        return next;
      });
    }
  }, [
    form,
    strategies,
    security,
    view,
    reject,
    limit,
    orderStartTime,
    orderEndTime,
    ccConversionRate,
    QuoteCurrency,
    BaseCurrency,
    fieldsDisabled,
    formatMessage,
  ]);

  return errors;
};

interface ParameterMetadata {
  orderEndTime?: Date | null;
  orderStartTime?: Date;
}

type getParameterValidationRuleProps = {
  formatMessage: IntlShape['formatMessage'];
  param: WLOrderStrategyParameter;
  security: Security;
  parameterMetadata: ParameterMetadata;
  fieldsDisabled?: FormDisabledState;
};

const getParameterValidationRule = ({
  formatMessage,
  param: parameter,
  security,
  parameterMetadata,
  fieldsDisabled,
}: getParameterValidationRuleProps) => {
  const { MinPriceIncrement, MinSizeIncrement } = security;
  switch (parameter.Type) {
    case 'Price': {
      let rule = number()
        .transform((_, value) => parseNumber(value))
        .nullable()
        .typeError(formatMessage(messages.displayNameShouldBeANumber, { displayName: parameter.DisplayName }))
        .min(0, formatMessage(messages.displayNameShouldBeGreaterThanZero, { displayName: parameter.DisplayName }))
        .test('precision', formatMessage(messages.minIncrementIsMinIncr, { minIncr: MinPriceIncrement }), value =>
          validatePrecision(MinPriceIncrement, value)
        );
      if (parameter.Presence === 'Required') {
        rule = rule.required(formatMessage(messages.displayNameIsRequired, { displayName: parameter.DisplayName }));
      }
      return rule;
    }
    case 'Qty': {
      let rule = number()
        .transform((_, value) => parseNumber(value))
        .nullable()
        .typeError(formatMessage(messages.displayNameShouldBeANumber, { displayName: parameter.DisplayName }))
        .min(0, formatMessage(messages.displayNameShouldBeGreaterThanZero, { displayName: parameter.DisplayName }))
        .test('precision', formatMessage(messages.minIncrementIsMinIncr, { minIncr: MinSizeIncrement }), value =>
          validatePrecision(MinSizeIncrement, value)
        );
      if (parameter.Presence === 'Required') {
        rule = rule.required(formatMessage(messages.displayNameIsRequired, { displayName: parameter.DisplayName }));
      }
      return rule;
    }
    case 'Date': {
      let rule = date()
        .nullable()
        .typeError(formatMessage(messages.displayNameShouldBeADate, { displayName: parameter.DisplayName }));
      if (parameter.Presence === 'Required') {
        rule = rule.required(formatMessage(messages.displayNameIsRequired, { displayName: parameter.DisplayName }));
      }
      if (parameter.Name === 'StartTime') {
        rule = rule.test({
          name: 'start-date-validity',
          test: function checkStartDateTimeValidity(value, { createError }) {
            if (value && value < new Date()) {
              if (!get(fieldsDisabled, parameter.Name)) {
                return createError({
                  message: formatMessage(messages.dateCannotBeInThePast),
                });
              }
            }

            if (value && parameterMetadata.orderEndTime && value > parameterMetadata.orderEndTime) {
              return createError({
                message: formatMessage(messages.displayNameCannotBeAfter, {
                  displayName: parameter.DisplayName,
                  prettyName: prettyName(WLParameterKeysEnum.EndTime),
                }),
              });
            }

            return true;
          },
        });
      }
      if (parameter.Name === 'EndTime') {
        rule = rule.test({
          name: 'end-date-validity',
          test: function checkEndDateTimeValidity(value, { createError }) {
            if (value && value < new Date()) {
              return createError({
                message: formatMessage(messages.dateCannotBeInThePast),
              });
            }

            if (value && parameterMetadata.orderStartTime && value < parameterMetadata.orderStartTime) {
              return createError({
                message: formatMessage(messages.displayNameCannotBeBefore, {
                  displayName: parameter.DisplayName,
                  prettyName: prettyName(WLParameterKeysEnum.StartTime),
                }),
              });
            }

            return true;
          },
        });
      }
      return rule;
    }
    case 'Percent': {
      let rule = number()
        .transform((_, value) => parseNumber(value))
        .nullable()
        .typeError(formatMessage(messages.displayNameShouldBeANumber, { displayName: parameter.DisplayName }))
        .max(100, formatMessage(messages.invalidPercentageValue))
        .min(0, formatMessage(messages.invalidPercentageValue))
        .test('precision', formatMessage(messages.minIncrementIsMinIncr, { minIncr: '1' }), q => {
          return q == null || validatePrecision('1', q);
        });
      if (parameter.Presence === 'Required') {
        rule = rule.required(formatMessage(messages.displayNameIsRequired, { displayName: parameter.DisplayName }));
      }
      return rule;
    }
    case 'PriceOffset': {
      let rule = number()
        .transform((_, value) => parseNumber(value))
        .nullable()
        .typeError(formatMessage(messages.displayNameShouldBeANumber, { displayName: parameter.DisplayName }))
        .min(0, formatMessage(messages.displayNameShouldBeGreaterThanZero, { displayName: parameter.DisplayName }))
        .test('precision', formatMessage(messages.minIncrementIsMinIncr, { minIncr: MinPriceIncrement }), value =>
          validatePrecision(MinPriceIncrement, value)
        );
      if (parameter.Presence === 'Required') {
        rule = rule.required(formatMessage(messages.displayNameIsRequired, { displayName: parameter.DisplayName }));
      }
      return rule;
    }
    case 'Interval': {
      let rule = string().matches(
        /\d+[smh]/,
        formatMessage(messages.displayNameMustBeAValidInterval, { displayName: parameter.DisplayName })
      );
      if (parameter.Presence === 'Required') {
        rule = rule.required(formatMessage(messages.displayNameIsRequired, { displayName: parameter.DisplayName }));
      }
      return rule;
    }
    default: {
      return mixed();
    }
  }
};

export const validatePrecision = (minSize: string | number, value?: string | number | null) => {
  if (value == null) {
    return true;
  }
  const quantity = Big(value);

  if (minSize === '0') {
    return true;
  }

  try {
    const scaledQuantity = quantity.div(minSize);
    const roundedQuantity = scaledQuantity.round();
    return scaledQuantity.eq(roundedQuantity);
  } catch (e) {
    // logger.error(e)
  }

  return true;
};

const runValidation = (schema: ObjectSchema<AnyObject>, form: AnyObject): { [key: string]: string } => {
  try {
    schema.validateSync(form, { abortEarly: false });
  } catch (err) {
    if (err instanceof ValidationError && err.inner) {
      const errors: { [key: string]: string } = {};
      for (const error of err.inner) {
        errors[error.path as string] = error.message;
      }
      return errors;
    }
  }
  return {};
};

type ValidationSchema = {
  [key: string]:
    | StringSchema<string | undefined | null>
    | NumberSchema<number | undefined | null>
    | DateSchema<Date | undefined | null>
    | MixedSchema;
};
