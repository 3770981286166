import type { ReactNode } from 'react';
import { defineMessages } from 'react-intl';
import styled, { css, keyframes } from 'styled-components';
import { Box } from '../Core';
import { FormattedMessage } from '../Intl';

const fadeIn = keyframes`
  0% { opacity: 0 }
  50% {opacity: 0 }
  100% { opacity: 1 }
`;

const messages = defineMessages({
  noRows: {
    defaultMessage: 'There are no rows to show',
    id: 'AgGrid.noRows',
  },
});

export const EmptyStateWrapper = styled(Box)<{ animate?: boolean }>`
  display: flex;
  background: var(--ag-background-color);
  align-items: center;
  ${({ animate }) =>
    animate &&
    css`
      animation: ${fadeIn} 250ms ease-out forwards;
    `}
`;
EmptyStateWrapper.defaultProps = {
  w: '100%',
  h: '100%',
  p: '6rem',
};

export const EmptyStateContent = styled(Box)<{ minHeight?: number }>`
  border: dashed 1px ${({ theme }) => theme.colors.white.mute};
  border-radius: ${({ theme }) => theme.borderRadiusHuge}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${({ theme, minHeight }) => minHeight ?? theme.baseSize * 3}px;
  height: 100%;
`;

export const EmptyState = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;

  h1,
  h2,
  i {
    color: ${({ theme }) => theme.colorTextMuted};
    font-weight: ${({ theme }) => theme.fontWeightRegular};
    margin: 0;

    & + * {
      margin-top: ${({ theme }) => theme.spacingLarge}px;
    }
  }
`;

export const AgGridNoRowsOverlay = ({ renderEmpty }: { renderEmpty: () => JSX.Element }) => {
  return renderEmpty != null ? renderEmpty() : <NoRowsOverlay />;
};

export const NoRowsOverlay = () => {
  return (
    <BlotterTableOverlay>
      <h2>
        <FormattedMessage {...messages.noRows} />
      </h2>
    </BlotterTableOverlay>
  );
};

export const BlotterTableOverlay = ({ children }: { children: ReactNode }) => {
  return (
    <EmptyStateWrapper>
      <EmptyStateContent>
        <EmptyState>{children}</EmptyState>
      </EmptyStateContent>
    </EmptyStateWrapper>
  );
};
