import Big from 'big.js';
import { useCallback } from 'react';
import type { Observable } from 'rxjs';
import { useMixpanel } from '../../contexts';
import { useObservableValue } from '../../hooks';
import { MixpanelEvent, MixpanelEventProperty } from '../../tokens';
import type { MarketDataSnapshot, Security } from '../../types';
import { format, toBigWithDefault } from '../../utils';
import { QuickOptions, type QuickOptionsProps } from './QuickOptions';

export interface PriceQuickOption {
  label: string;
  value: string;
}

export type PriceInputMode = 'Increment' | 'Reset';

export type PriceQuickOptionsProps<T> = {
  security: Security;
  options: PriceQuickOption[];
  onChange: (value: string) => void;
  marketDataObs: Observable<MarketDataSnapshot>;
  value?: string;
  priceInputMode?: PriceInputMode;
} & Omit<QuickOptionsProps<T>, 'onOptionClick' | 'options'>;

const isMarketDataValid = (symbol: string, marketData?: MarketDataSnapshot): marketData is MarketDataSnapshot => {
  if (!marketData) {
    return false;
  }
  return marketData.Symbol === symbol;
};

/**
 * Determines if a given option should be disabled based on the market data and side (Bid, Offer).
 * @param {string} optionValue - The value of the option to check.
 * @param {MarketDataSnapshot} [marketData] - The current market data snapshot.
 * @returns {boolean} - True if the option should be disabled, false otherwise.
 */
const isOptionDisabled = (optionValue: string, marketData?: MarketDataSnapshot): boolean => {
  // Negative value means one of 'bid' options, positive value means one of 'offer' options
  return optionValue.startsWith('-') || optionValue === 'bid'
    ? marketData?.Bids?.length === 0
    : marketData?.Offers?.length === 0;
};

export const PriceQuickOptions = <T,>({
  security,
  options,
  onChange,
  marketDataObs,
  disabled,
  value,
  priceInputMode,
  ...otherQuickOptionProps
}: PriceQuickOptionsProps<T>) => {
  const marketData = useObservableValue(() => marketDataObs, [marketDataObs]);
  const mixpanel = useMixpanel();

  const handleOptionClicked = useCallback(
    (option: string) => {
      if (!isMarketDataValid(security.Symbol, marketData)) {
        return;
      }

      let price;

      switch (option) {
        case 'bid':
          price = Big(marketData.Bids[0].Price);
          mixpanel.track(MixpanelEvent.PriceOffsetBid);
          break;
        case 'offer':
          price = Big(marketData.Offers[0].Price);
          mixpanel.track(MixpanelEvent.PriceOffsetOffer);
          break;
        default: {
          const bps = parseFloat(option);
          const priceToUse = bps > 0 ? marketData.Offers[0].Price : marketData.Bids[0].Price;
          if (priceInputMode === 'Increment') {
            const currentValue = toBigWithDefault(value, toBigWithDefault(priceToUse, 0).toNumber());
            const diff = currentValue.times(bps);
            price = currentValue.plus(diff);
          } else {
            const diff = Big(priceToUse).times(bps);
            price = Big(priceToUse).plus(diff);
          }
          mixpanel.track(MixpanelEvent.PriceOffsetBPS, { [MixpanelEventProperty.Value]: option });
          break;
        }
      }

      onChange(format(price, { spec: security.MinPriceIncrement, pretty: false, truncate: true }));
    },
    [marketData, onChange, security.MinPriceIncrement, security.Symbol, priceInputMode, value, mixpanel]
  );

  return (
    <QuickOptions
      {...otherQuickOptionProps}
      options={options}
      onOptionClick={handleOptionClicked}
      disabled={disabled || !isMarketDataValid(security.Symbol, marketData)}
      isOptionDisabled={(optionValue: string) => isOptionDisabled(optionValue, marketData)}
    />
  );
};
