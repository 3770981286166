import type { CSSProperties, ReactNode, SVGAttributes } from 'react';
import { useTheme, type DefaultTheme } from 'styled-components';
import { getCurrencyColor } from '../../hooks/';
import { parseColor, parseIconSize } from '../../styles';
import * as Icons from './imports';
import { currencyIconMap } from './map';

type CurrencyIconProps = {
  currency?: string;
  size?: number | string;
  style?: CSSProperties;
  color?: string;
  colorful?: boolean;
  theme?: DefaultTheme;
  stacked?: boolean;
};

export function CurrencyIcon({
  currency = 'Placeholder',
  size = 16,
  style = { verticalAlign: 'middle', flex: '0 0 auto' },
  color,
  colorful,
  theme: passedTheme,
  stacked = false,
  ...props
}: CurrencyIconProps) {
  const talosTheme = useTheme();
  const theme = passedTheme ?? talosTheme;
  const { colorCurrencyIcon, colorModeCurrencyIcon } = theme;
  colorful = colorful ?? colorModeCurrencyIcon === 'colorful';

  let parsedColor = color ? parseColor(theme, color) : colorCurrencyIcon;
  const parsedSize = parseIconSize(theme, size);

  const currencyItem: { symbol: string } | undefined = currencyIconMap[currency];

  const allIcons: { [key: string]: any } = Icons;
  const showRealIcon = !!(currencyItem && allIcons[`CurrencyIcon${currencyItem.symbol}`]);
  const Icon = showRealIcon ? allIcons[`CurrencyIcon${currencyItem.symbol}`] : undefined;

  const currencyColor = getCurrencyColor(currency);
  const circleFill = (colorful && currencyColor) || 'currentColor';

  if (circleFill !== 'currentColor') {
    parsedColor = '#fff'; // this hardcoding is correct
  }

  return (
    <CurrencyIconWrapper stacked={stacked} fill={circleFill} height={parsedSize} style={style}>
      <svg
        viewBox="0 0 32 32"
        preserveAspectRatio="xMinYMin meet"
        height={!stacked ? parsedSize : undefined}
        style={style}
        {...props}
      >
        <circle fill={circleFill} stroke="none" cx="16px" cy="16px" r="15px" />
        {Icon != null ? (
          <Icon color={parsedColor} />
        ) : (
          <text fill={parsedColor} dy="0.32em" x="50%" y="50%" textAnchor="middle" fontSize="1.25rem">
            {currency && currency !== 'Placeholder' ? currency[0] : ''}
          </text>
        )}
      </svg>
    </CurrencyIconWrapper>
  );
}

interface CurrencyIconWrapperProps {
  children: ReactNode;
  stacked?: boolean;
  height: SVGAttributes<SVGElement>['height'];
  style?: CSSProperties;
  fill: SVGAttributes<SVGElement>['fill'];
}

const CurrencyIconWrapper = ({ children, stacked = false, height, style, fill }: CurrencyIconWrapperProps) => {
  if (stacked) {
    return (
      <svg height={height} viewBox="0 0 46 32" style={style}>
        <path
          d="M32.0051 31.675C37.4675 28.5125 41.1421 22.6049 41.1421 15.839C41.1421 9.07099 37.4652 3.16179 32 0C39.7535 1.10939 45.7136 7.7776 45.7136 15.8379C45.7136 23.8964 39.7561 30.5634 32.0051 31.675Z"
          fill={fill}
        />
        <path
          d="M25.1451 31.8367C30.6074 28.6742 34.2821 22.7665 34.2821 16.0007C34.2821 9.23268 30.6051 3.32348 25.1399 0.161686C32.8934 1.27107 38.8535 7.93928 38.8535 15.9996C38.8535 24.0581 32.896 30.7251 25.1451 31.8367Z"
          fill={fill}
        />
        {children}
      </svg>
    );
  }
  return <>{children}</>;
};
