import { defineMessages } from 'react-intl';

export const MobileLabelMessages = defineMessages({
  watchlist: {
    defaultMessage: 'Watchlist',
    id: 'layouts.mobileScreens.watchlist',
  },
  trade: {
    defaultMessage: 'Trade',
    id: 'layouts.mobileScreens.trade',
  },
  orders: {
    defaultMessage: 'Orders',
    id: 'layouts.mobileScreens.orders',
  },
  balances: {
    defaultMessage: 'Balances',
    id: 'layouts.mobileScreens.balances',
  },
  trades: {
    defaultMessage: 'Trades',
    id: 'layouts.mobileScreens.trades',
  },
  credit: {
    defaultMessage: 'Credit',
    id: 'layouts.mobileScreens.credit',
  },
  transfers: {
    defaultMessage: 'Transfers',
    id: 'layouts.mobileScreens.transfers',
  },
  ladder: {
    defaultMessage: 'Ladder',
    id: 'layouts.mobileScreens.ladder',
  },
  chart: {
    defaultMessage: 'Chart',
    id: 'layouts.mobileScreens.chart',
  },
  settings: {
    defaultMessage: 'Settings',
    id: 'layouts.mobileScreens.settings',
  },
});
