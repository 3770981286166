import { useCallback, useMemo, useRef } from 'react';
import { defineMessages } from 'react-intl';

import { Button } from '../Button';
import { Flex, HStack } from '../Core';
import {
  AutocompleteDropdown,
  FormControlSizes,
  FuseAutocompleteResult,
  Input,
  useDropdownPopper,
  useSearchSelect,
} from '../Form';
import { FormattedMessage } from '../Intl';
import { Text } from '../Text';

import { useMixpanel } from '../../contexts/MixpanelContext';
import { MixpanelEvent, MixpanelEventProperty } from '../../tokens/mixpanel';
import { FlagLineContainer } from './styles';
import { supportedLocales } from './supportedLocales';
import type { LocaleItem } from './types';

const messages = defineMessages({
  language: {
    defaultMessage: 'Language',
    id: 'LocaleSelector.language',
  },
});

export interface LocaleSelectorProps {
  locale: string;
  setLocale: (locale: string) => void;
}

const getLabel = (locale: LocaleItem) => `${locale.displayName} ${locale.flag}`;

export function LocaleSelector({ locale, setLocale }: LocaleSelectorProps) {
  const mixpanel = useMixpanel();

  const handleUpdateLocale = useCallback(
    (newLocale?: LocaleItem) => {
      if (newLocale) {
        setLocale(newLocale.value);
        // Track the locale change event
        mixpanel.track(MixpanelEvent.SetUILocale, { [MixpanelEventProperty.Locale]: newLocale.value });
      }
    },
    [mixpanel, setLocale]
  );

  const selection = useMemo(() => {
    let selection = supportedLocales.find(l => l.value === locale);
    if (!selection) {
      const [language] = locale.split('-');
      selection = supportedLocales.find(l => l.value === language);
    }

    return selection;
  }, [locale]);

  const inputRef = useRef(null);

  const searchSelect = useSearchSelect<LocaleItem>({
    selection,
    items: supportedLocales,
    inputRef,
    initialSortByLabel: false,
    getLabel,
    onChange: handleUpdateLocale,
  });

  const { getInputProps, openMenu, isOpen } = searchSelect;

  const dropdownReferenceElement = useRef<HTMLDivElement | null>(null);
  const dropdownPopper = useDropdownPopper({
    isOpen,
    dropdownWidth: '100px',
    dropdownPlacement: 'bottom-end',
    referenceElement: dropdownReferenceElement.current ?? null,
  });

  return (
    <Flex ref={dropdownReferenceElement} justifyContent="center" data-testid="locale-selector">
      <Button prominent onClick={openMenu}>
        <HStack gap="spacingDefault">
          <Text>
            <FormattedMessage {...messages.language} />
            {selection?.displayName ? `: ${selection.displayName} ${selection.flag}` : ''}
          </Text>
        </HStack>
      </Button>
      <FlagLineContainer>
        <AutocompleteDropdown
          {...searchSelect}
          {...dropdownPopper}
          renderResult={FuseAutocompleteResult}
          size={FormControlSizes.Default}
          childrenAboveResults={<Input style={{ display: 'none' }} {...getInputProps({ ref: inputRef })} />}
        />
      </FlagLineContainer>
    </Flex>
  );
}
