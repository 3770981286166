import { css } from 'styled-components';

export const borderCss = (borderSize: number) => css`
  ${({ theme }) => {
    if (theme.chamferMultiplier) {
      return css`
        --offset: min(6px, ${borderSize}px);
        --offset-low: var(--offset);
        --offset-high: calc(100% - var(--offset));

        clip-path: polygon(
          100% var(--offset-low),
          var(--offset-high) 0%,
          var(--offset-low) 0%,
          0% var(--offset-low),
          0% var(--offset-high),
          var(--offset-low) 100%,
          var(--offset-high) 100%,
          100% var(--offset-high)
        );
        border-radius: 0;
        border-width: 0;
        outline: unset;
      `;
    } else {
      return css`
        border-radius: ${borderSize}px;
      `;
    }
  }}
`;
