import type { CSSProperties } from 'react';
import styled, { type DefaultTheme } from 'styled-components';
import { parseColor, parseFontSize } from '../../styles';
import { NumberVariants } from './types';

export const RoundedNumber = styled.span`
  opacity: 1;
  transition: opacity 200ms 100ms ease;
`;

export const FullNumber = styled.span<{ align?: 'left' | 'right' | 'center'; background?: string }>`
  position: absolute;
  transition: opacity 200ms 100ms ease;
  ${({ align }) => (align === 'right' ? `right: 0` : `left: 0`)};
  opacity: 0;
  white-space: nowrap;
  padding: 0 2px;
  margin: 0 -2px;

  background: ${({ background, theme }) => (background ? parseColor(theme, background) : theme?.backgroundContent)};
  box-shadow: ${({ background, theme }) =>
    `0 0 0.625em ${background ? parseColor(theme, background) : theme?.backgroundContent}`};
`;

export const Indicator = styled.span<{ variant?: NumberVariants }>`
  width: 0;
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  top: -1px;
  position: absolute;
  display: inline-block;
  color: ${({ variant, theme }) => getColor(theme, variant, true, 'inherit')};
`;

export const NumberWrapper = styled.span<{ estimate: boolean; background?: string }>`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacingTiny}px;
  position: relative;
  font-style: ${({ estimate }) => (estimate ? 'italic' : 'normal')};

  &:hover {
    background: ${({ background }) => background ?? 'inherit'};
    overflow: visible;
    ${RoundedNumber} {
      opacity: 0;
    }

    ${FullNumber} {
      opacity: 1;
      z-index: 1;
    }
  }
`;

export const Number = styled.span<{ isSmall?: boolean; variant?: NumberVariants }>`
  font-size: ${({ isSmall, theme }) => (isSmall ? theme.fontSizeSmall : 'inherit')}rem;
  color: ${({ variant, theme }) => getColor(theme, variant, true, 'inherit')};
  transition: color 200ms ease;
  overflow-y: hidden;
`;

export const Highlight = styled.span<{
  largeHighlight?: boolean;
  highlightSize?: number;
  highlightColor?: string;
  variant?: NumberVariants;
  isSmall?: boolean;
}>`
  font-size: ${({ largeHighlight, highlightSize, theme }) =>
    highlightSize ? `${highlightSize}rem` : largeHighlight ? `${theme.fontSizeLarge}rem` : 'inherit'};
  transition: color 200ms ease;
  color: ${({ variant, highlightColor, theme }) => getColor(theme, variant, true, highlightColor)};
  ${props =>
    props.largeHighlight &&
    `
		margin-left: 1px;
		margin-right: 1px;
	`}
`;

export const Currency = styled.span<{ isSmall?: boolean; variant?: NumberVariants; highlightColor?: string }>`
  font-size: ${({ isSmall, theme }) => (isSmall ? theme.fontSizeSmall : 'inherit')}rem;
  opacity: 0.7;
  color: ${({ variant, highlightColor, theme }) => highlightColor ?? getColor(theme, variant)};
  transition: color 200ms ease;
`;

export const StackedNumber = styled.div<{
  large?: boolean;
  stack?: 'bottom' | 'top' | 'middle';
  highlight: boolean;
  variant?: NumberVariants;
  largeSize: CSSProperties['fontSize'];
  baseSize: CSSProperties['fontSize'];
}>`
  font-size: ${({ theme, large, largeSize = '1.75rem', baseSize = 'fontSizeLarge' }) =>
    large ? parseFontSize(theme, largeSize) : parseFontSize(theme, baseSize)};
  text-align: ${({ stack }) => (stack === 'bottom' ? 'right' : stack === 'top' ? 'left' : 'right')};
  color: ${({ highlight, variant, theme }) => getColor(theme, variant, highlight)};
  line-height: 1;
  transition: color 200ms ease;
`;

const getColor = (theme: DefaultTheme, variant?: NumberVariants, highlight = true, defaultColor?: string) => {
  switch (variant) {
    case NumberVariants.Positive: {
      return highlight ? theme.colorTextPositive : theme.colorTextPositiveSubtle;
    }
    case NumberVariants.Negative: {
      return highlight ? theme.colorTextNegative : theme.colorTextNegativeSubtle;
    }
    case NumberVariants.Warning: {
      return highlight ? theme.colorTextWarning : theme.colorTextWarningSubtle;
    }
    case NumberVariants.Primary: {
      return highlight ? theme.colorTextPrimary : theme.colorTextPrimarySubtle;
    }
    case NumberVariants.Muted: {
      return highlight ? theme.colorTextDefault : theme.colorTextSubtle;
    }
    default: {
      if (highlight) {
        return defaultColor != null ? parseColor(theme, defaultColor) : theme.colorStackedNumberHighlight;
      } else {
        return defaultColor != null ? parseColor(theme, defaultColor) : theme.colorStackedNumberNoHighlight;
      }
    }
  }
};
