import { CustomerUserConfigTradingLayoutArrangements, ResizerPlacement } from '@talos/kyoko';
import { Blotters } from '../../../components/Blotters/Blotters';
import { OrderForms } from '../../../components/OrderForms';
import { Securities } from '../../../components/Securities';
import { BLOTTERS_MIN_HEIGHT, MAIN_HEADER_HEIGHT, VOLUME_LADDER_HEIGHT } from '../../../styles/dimensions';
import { tradingMobileScreens } from '../../mobileScreens';
import type { LayoutComponentArrangement } from '../../types';

export const MarketDataCardsArrangement: LayoutComponentArrangement = {
  columns: ['minmax(16rem,1fr)', 'minmax(16rem,1fr)', 'minmax(16rem,24rem)', 'auto'],
  headerSymbolSelector: false,
  displayName: CustomerUserConfigTradingLayoutArrangements.MarketDataCards,
  rows: [`minmax(${VOLUME_LADDER_HEIGHT}px,1fr)`, 'minmax(0px,1fr)', `minmax(${BLOTTERS_MIN_HEIGHT}px,1fr)`],
  parts: [
    {
      column: '1/4',
      row: '1/3',
      component: <Securities />,
    },
    {
      column: '1/4',
      row: '3',
      component: <Blotters />,
      minHeight: BLOTTERS_MIN_HEIGHT,
      maxHeight: window.innerHeight - MAIN_HEADER_HEIGHT - VOLUME_LADDER_HEIGHT - 20,
      resizable: ResizerPlacement.TOP,
    },
    {
      column: '4',
      row: '1/4',
      component: <OrderForms />,
      isOrderForms: true,
    },
  ],
  mobileScreens: tradingMobileScreens,
};
