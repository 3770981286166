import { Box, type CustomerOrder, Flex, LoaderTalos, useDynamicCallback } from '@talos/kyoko';
import { useCallback, useMemo, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { DEFAULT_CARD_HEIGHT, WindowedExecutionCard, type WindowedExecutionCardDataProps } from './Card';
export const ActiveOrdersList = ({
  activeOrders,
  handleArchiveOrder,
  handleCancelOrder,
  handleModifyOrder,
  handleResubmitOrder,
}: {
  handleCancelOrder: (orderID: string) => void;
  handleArchiveOrder: (orderID: string) => void;
  handleModifyOrder: (order: CustomerOrder) => void;
  handleResubmitOrder: (order: CustomerOrder) => void;
  activeOrders: CustomerOrder[] | undefined;
}) => {
  const listRef = useRef<VariableSizeList<any> | null>(null);

  const setRowHeight = useDynamicCallback((index: number, size: number) => {
    listRef.current?.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  });
  const rowHeights = useRef<Record<number, number>>({});
  const getRowHeight = useCallback((index: number) => rowHeights.current[index] || DEFAULT_CARD_HEIGHT, [rowHeights]);
  const itemData: WindowedExecutionCardDataProps = useMemo(
    () => ({
      items: activeOrders ?? [],
      setRowHeight,
      handleCancelOrder,
      handleModifyOrder,
      handleResubmitOrder,
      handleArchiveOrder,
    }),
    [activeOrders, setRowHeight, handleCancelOrder, handleModifyOrder, handleResubmitOrder, handleArchiveOrder]
  );

  if (!activeOrders) {
    return (
      <Flex alignItems="center" justifyContent="center" h="100%">
        <LoaderTalos delay={300} />
      </Flex>
    );
  }

  return activeOrders.length === 0 ? (
    <Flex alignItems="center" justifyContent="center" h="100%">
      No active orders
    </Flex>
  ) : (
    <Box h="100%" overflowX="hidden">
      <AutoSizer disableWidth>
        {({ height }) => (
          <VariableSizeList
            height={height}
            itemCount={activeOrders.length}
            itemSize={getRowHeight}
            itemData={itemData}
            ref={listRef}
            width="100%"
            style={{ overflowX: 'hidden', overflowY: 'auto' }}
          >
            {WindowedExecutionCard}
          </VariableSizeList>
        )}
      </AutoSizer>
    </Box>
  );
};
