import type { BoxProps, IconName, LayoutRequiredPermissions, ResizerPlacement, WLOrgConfig } from '@talos/kyoko';
import type { ReactElement, ReactNode } from 'react';

export type LayoutPart = {
  column: string;
  row: string;
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
  resizable?: ResizerPlacement;
  isOrderForms?: boolean;
} & BoxProps;

export type LayoutComponentPart = LayoutPart & {
  component: ReactNode | Omit<LayoutComponentArrangement, 'displayName' | 'headerSymbolSelector'>;
};

export type LayoutComponentScreen = {
  label: string;
  path: string;
  component: ReactElement;
  icon: IconName;
  orientation?: ScreenOrientationEnum;
  requiredPermissions?: string[];
  requiredConfigs?: (keyof WLOrgConfig)[];
};

export type LayoutComponentArrangement = {
  columns: string[];
  displayName: string;
  headerSymbolSelector?: boolean;
  rows: string[];
  parts: LayoutComponentPart[];
  intlKey?: string;
  mobileScreens?: LayoutComponentScreen[];
};

export type SingleLayoutComponent = Omit<LayoutComponentArrangement, 'displayName'> & {
  showOrderFormToggle?: 'right' | 'left';
  maxWidth?: number;
};

type ArrangementMap = Record<string, LayoutComponentArrangement>;

export type MultiLayoutComponent = {
  arrangements: ArrangementMap;
  arrangement?: string;
  showOrderFormToggle?: 'right' | 'left';
  maxWidth?: number;
  mobileScreens?: LayoutComponentScreen[];
};

export type LayoutComponent = SingleLayoutComponent;

export type LayoutSpec = {
  layout: SingleLayoutComponent | MultiLayoutComponent;
  requiredPermissions?: LayoutRequiredPermissions;
}[];

/** Names from https://developer.mozilla.org/en-US/docs/Web/API/ScreenOrientation/lock */
export enum ScreenOrientationEnum {
  any = 'any',
  natural = 'natural',
  portrait = 'portrait',
  portraitPrimary = 'portrait-primary',
  portraitSecondary = 'portrait-secondary',
  landscape = 'landscape',
  landscapePrimary = 'landscape-primary',
  landscapeSecondary = 'landscape-secondary',
}
