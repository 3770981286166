import type { ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';
import { CustomerOrder } from '../../../types';
import { getOrderStatusText, OrderStatus, type OrderStatusText } from '../../Status';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';
import { stringColumnComparator } from './utils';

type OrderStatusValue = { statusText: OrderStatusText; Text?: string } | undefined;
export const orderStatus: ColDefFactory<Column> = column => {
  return {
    ...baseColumn(column),
    valueGetter: ({ data }: ValueGetterParams): OrderStatusValue => {
      if (!data) {
        return;
      }
      const { DecisionStatus, OrdStatus, CumQty, OrderQty, PricingMode, Text } = data;
      const statusText = getOrderStatusText({
        ordStatus: OrdStatus,
        decisionStatus: DecisionStatus,
        cumQty: CumQty,
        orderQty: OrderQty,
        pricingMode: data instanceof CustomerOrder ? undefined : PricingMode,
      });

      return { statusText, Text };
    },
    valueFormatter: ({ value }: ValueFormatterParams<unknown, OrderStatusValue>) => {
      if (!value) {
        return '';
      }
      return value.statusText;
    },
    cellRenderer: (params: ICellRendererParams) => {
      const data = params.data;
      if (!data) {
        return params.value;
      }
      const { OrdStatus, OrderQty, CumQty, DecisionStatus, Text, PricingMode } = data;
      return (
        <OrderStatus
          theme={params.context.current.theme}
          ordStatus={OrdStatus}
          decisionStatus={DecisionStatus}
          cumQty={CumQty}
          orderQty={OrderQty}
          pricingMode={data instanceof CustomerOrder ? undefined : PricingMode}
          text={Text}
          iconPlacement="left"
        />
      );
    },
    comparator: (valueA: OrderStatusValue, valueB: OrderStatusValue) =>
      stringColumnComparator(valueA?.statusText, valueB?.statusText),
    minWidth: 180,
    maxWidth: 220,
    flex: 1,
  };
};
