import { type SubAccountReconMatch, SubAccountReconMatchStatusEnum } from '../../types';
import { HStack, VStack } from '../Core';
import { Icon, IconName, type IconProps } from '../Icons';
import { IndicatorDotVariants, IndicatorDotWrapper } from '../IndicatorDot';
import { Text } from '../Text';
import { JITTooltip } from '../Tooltip';

const StatusIcon = ({
  status,
  ...iconProps
}: { status: SubAccountReconMatch['Status'] } & Omit<IconProps, 'icon' | 'color'>) => {
  switch (status) {
    case SubAccountReconMatchStatusEnum.Matched:
      return <Icon icon={IconName.CheckCircleSolid} color="colorTextPositive" {...iconProps} />;
    case SubAccountReconMatchStatusEnum.Resolved:
      return <Icon icon={IconName.CheckCircleSolid} color="colors.blue.lighten" {...iconProps} />;
    case SubAccountReconMatchStatusEnum.Unmatched:
    case SubAccountReconMatchStatusEnum.UnmatchedMarketAccount:
    case SubAccountReconMatchStatusEnum.UnmatchedSubAccount:
      return <Icon icon={IconName.QuestionMarkCircle} color="colorTextWarning" {...iconProps} />;
    case SubAccountReconMatchStatusEnum.Discarded:
      return <Icon icon={IconName.CloseCircle} color="colorTextDefault" {...iconProps} />;
    case SubAccountReconMatchStatusEnum.Ignored:
      return <Icon icon={IconName.CheckCircleSolid} color="colorTextDefault" {...iconProps} />;
    default:
      // dont show any icon if we dont know what the status is
      return null;
  }
};

export const RECON_MATCH_STATUS_TEXT_MAPPING: { [key in SubAccountReconMatchStatusEnum]: string } = {
  Unmatched: 'Unmatched',
  UnmatchedMarketAccount: 'Unmatched Market Account',
  UnmatchedSubAccount: 'Unmatched Sub Account',
  Matched: 'Matched',
  Resolved: 'Resolved',
  Discarded: 'Discarded',
  Ignored: 'Ignored',
};

export const ReconMatchStatus = ({
  status,
  comments,
  textColor,
}: {
  status: SubAccountReconMatch['Status'];
  comments?: SubAccountReconMatch['Comments'];
  textColor?: string;
}) => {
  // in case the backend is ahead of us for some reason
  const statusText = RECON_MATCH_STATUS_TEXT_MAPPING[status] ?? status;

  const mainContent = (
    <HStack gap="spacingSmall">
      <IndicatorDotWrapper show={!!comments} variant={IndicatorDotVariants.Warning}>
        <StatusIcon status={status} />
      </IndicatorDotWrapper>
      <Text color={textColor}>{statusText}</Text>
    </HStack>
  );

  return (
    <JITTooltip
      tooltip={
        <VStack gap="spacingSmall" alignItems="flex-start">
          {comments?.map((c, i) => (
            <Text key={i}>{c}</Text>
          ))}
        </VStack>
      }
    >
      {mainContent}
    </JITTooltip>
  );
};
