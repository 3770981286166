import { Box, TabList, TabPanel, TabPanels } from '@talos/kyoko';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;

  ${TabList} {
    padding: ${({ theme }) => `0 ${theme.spacingDefault}px`};
  }

  ${TabPanels}, ${TabPanel} {
    height: 100%;
  }
`;

export const BlotterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: ${({ theme }) => theme.spacingMedium}px;
`;

export const BlotterActions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacingMedium}px;
`;

export const BlotterTitle = styled.div`
  margin: ${({ theme }) => `${theme.spacingComfortable}px ${theme.spacingMedium}px `};
  color: ${({ theme }) => theme.colorTextImportant};
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colorTextImportant};
  padding: ${({ theme }) => theme.spacingMedium}px;
`;

export const BlotterWrapper = styled(Box)``;
BlotterWrapper.defaultProps = {
  display: 'flex',
  h: '100%',
  flex: '1 1 0',
  flexDirection: 'column',
  overflow: 'hidden',
};

export const Content = styled.div`
  flex: 1 1 0;
  width: calc(100% + 1px);
`;
