import { darken, lighten, rgba } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { css, keyframes } from 'styled-components';
import { parseColor } from './themes/utils';

/**
 *
 * @param theme The styled-components theme
 * @param color The color you wish to apply some darken or lighten to
 * @param diff The amount, 0 to 1, you wish to apply darken or lighten with. Defaults to 0.05.
 * @returns a new string color
 */
export function hoverColor(theme: Pick<DefaultTheme, 'type'>, color: string, diff = 0.05): string {
  if (theme.type === 'light') {
    return darken(diff, color);
  } else {
    return lighten(diff, color);
  }
}

/**
 * Adjust the opacity of a color by setting the alpha value of an opaque color
 * @param amount The opacity level for the color
 * @param color The color to adjust
 * @returns a color string with the correct alpha value
 */
export function setAlpha(amount: number, color: string) {
  return rgba(color, amount);
}
// https://stackoverflow.com/a/1636354/11076115
const hexRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
const hslRegex =
  /hsla?\((?:(-?\d+(?:deg|g?rad|turn)?),\s*((?:\d{1,2}|100)%),\s*((?:\d{1,2}|100)%)(?:,\s*((?:\d{1,2}|100)%|0(?:\.\d+)?|1))?|(-?\d+(?:deg|g?rad|turn)?)\s+((?:\d{1,2}|100)%)\s+((?:\d{1,2}|100)%)(?:\s+((?:\d{1,2}|100)%|0(?:\.\d+)?|1))?)\)/;
const rgbRegex =
  /rgba?\((?:(25[0-5]|2[0-4]\d|1?\d{1,2}|(?:\d{1,2}|100)%),\s*(25[0-5]|2[0-4]\d|1?\d{1,2}|(?:\d{1,2}|100)%),\s*(25[0-5]|2[0-4]\d|1?\d{1,2}|(?:\d{1,2}|100)%)(?:,\s*((?:\d{1,2}|100)%|0(?:\.\d+)?|1))?|(25[0-5]|2[0-4]\d|1?\d{1,2}|(?:\d{1,2}|100)%)\s+(25[0-5]|2[0-4]\d|1?\d{1,2}|(?:\d{1,2}|100)%)\s+(25[0-5]|2[0-4]\d|1?\d{1,2}|(?:\d{1,2}|100)%)(?:\s+((?:\d{1,2}|100)%|0(?:\.\d+)?|1))?)\)/;

/**
 * Check if a given string is usable as a CSS color
 * @param color the color you want to validate
 * @returns true if the string is a valid color string
 */
export const isAdjustableColor = (color: string) => {
  const s = new Option().style;
  s.color = color;

  if (s.color === '' || color.includes('url') || color.includes('gradient')) {
    return false;
  }

  if (color.length > 0) {
    if (hexRegex.test(color) || hslRegex.test(color) || rgbRegex.test(color)) {
      return true;
    }
  }

  return false;
};

const loading = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -400% -400%;
  }
`;

/** Get an animated background for a loading container */
export function getLoadingAnimation(
  theme: DefaultTheme,
  isLoading: boolean | undefined,
  background: string,
  darkerBackground?: string
) {
  return isLoading
    ? css`
        background: linear-gradient(
          270deg,
          ${parseColor(theme, background) ?? background},
          ${parseColor(theme, darkerBackground) ?? darken(0.05, parseColor(theme, background) ?? background)},
          ${parseColor(theme, background) ?? background}
        );
        background-position: 0% 0%;
        background-size: 400%;
        animation: ${loading} 4s linear infinite;
      `
    : `background: ${parseColor(theme, background) ?? background};`;
}

export function getMutedButtonStyles(colors: Colors, customMappings: Partial<DefaultTheme>) {
  return {
    backgroundMutedButton:
      customMappings.backgroundMutedButton ||
      customMappings.backgroundDefaultButton ||
      setAlpha(0.08, colors.gray['100']),
    backgroundMutedButtonHover:
      customMappings.backgroundMutedButtonHover ||
      customMappings.backgroundDefaultButtonHover ||
      setAlpha(0.12, colors.gray['100']),
    backgroundMutedButtonFocus:
      customMappings.backgroundMutedButtonFocus ||
      customMappings.backgroundDefaultButtonFocus ||
      setAlpha(0.12, colors.gray['100']),
    backgroundMutedButtonActive:
      customMappings.backgroundMutedButtonActive ||
      customMappings.backgroundDefaultButtonActive ||
      setAlpha(0.08, colors.gray['100']),
    backgroundMutedButtonDisabled:
      customMappings.backgroundMutedButtonDisabled ||
      customMappings.backgroundDefaultButtonDisabled ||
      setAlpha(0.04, colors.gray['100']),

    boxShadowMutedButton: customMappings.boxShadowMutedButton || customMappings.boxShadowDefaultButton || 'none',
    boxShadowMutedButtonHover:
      customMappings.boxShadowMutedButtonHover || customMappings.boxShadowDefaultButtonHover || 'none',
    boxShadowMutedButtonFocus:
      customMappings.boxShadowMutedButtonFocus || customMappings.boxShadowDefaultButtonFocus || 'none',
    boxShadowMutedButtonActive:
      customMappings.boxShadowMutedButtonActive || customMappings.boxShadowDefaultButtonActive || 'none',
    boxShadowMutedButtonDisabled:
      customMappings.boxShadowMutedButtonDisabled || customMappings.boxShadowDefaultButtonDisabled || 'none',

    colorTextMutedButton:
      customMappings.colorTextMutedButton || customMappings.colorTextDefaultButton || colors.gray['100'],
    colorTextMutedButtonHover:
      customMappings.colorTextMutedButtonHover || customMappings.colorTextDefaultButtonHover || colors.gray['100'],
    colorTextMutedButtonFocus:
      customMappings.colorTextMutedButtonFocus || customMappings.colorTextDefaultButtonFocus || colors.gray['100'],
    colorTextMutedButtonActive:
      customMappings.colorTextMutedButtonActive || customMappings.colorTextDefaultButtonActive || colors.gray['100'],
    colorTextMutedButtonDisabled:
      customMappings.colorTextMutedButtonDisabled ||
      customMappings.colorTextDefaultButtonDisabled ||
      setAlpha(0.5, colors.gray['100']),

    borderColorMutedButton:
      customMappings.borderColorMutedButton || customMappings.borderColorDefaultButton || 'transparent',
    borderColorMutedButtonHover:
      customMappings.borderColorMutedButtonHover || customMappings.borderColorDefaultButtonHover || 'transparent',
    borderColorMutedButtonFocus:
      customMappings.borderColorMutedButtonFocus || customMappings.borderColorDefaultButtonFocus || 'transparent',
    borderColorMutedButtonActive:
      customMappings.borderColorMutedButtonActive || customMappings.borderColorDefaultButtonActive || 'transparent',
    borderColorMutedButtonDisabled:
      customMappings.borderColorMutedButtonDisabled || customMappings.borderColorDefaultButtonDisabled || 'transparent',
  } satisfies Partial<DefaultTheme>;
}
