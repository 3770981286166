import type { Observable } from 'rxjs';
import { useTheme } from 'styled-components';
import { useHistoricalPrices } from '../../../hooks';
import type { MarketDataSnapshot, Security } from '../../../types';
import { FormGroup } from '../../DateTimeDurationPicker/styles';
import { Text } from '../../Text';
import { MarketView } from '../../VolumeLadder/MarketView';
import type { ClickRowCallback } from '../../VolumeLadder/types';

const resolution = '1H';

export function OrderFormMarketView({
  marketDataSnapshots,
  onClickRow,
  security,
}: {
  marketDataSnapshots: Observable<MarketDataSnapshot>;
  onClickRow: ClickRowCallback;
  security?: Security;
}) {
  const sparklineDataObservable = useHistoricalPrices({
    clearOnSymbolChange: true,
    symbol: security?.Symbol,
    resolution,
  });

  const { spacing8, spacing16 } = useTheme();

  return (
    <FormGroup style={{ position: 'relative', margin: `0 0 ${spacing16}px` }}>
      <MarketView
        marketDataObservable={marketDataSnapshots}
        onClickRow={onClickRow}
        resolution={resolution}
        security={security}
        sparklineDataObservable={sparklineDataObservable}
      />
      <Text style={{ position: 'absolute', top: 0, right: 0, padding: spacing8 }}>{resolution}</Text>
    </FormGroup>
  );
}
