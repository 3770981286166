import {
  DEFAULT_MAX_ROWS,
  IconName,
  WL_VIEW_BLOTTERS_TRANSFERS,
  WL_VIEW_SETTINGS,
  type WLOrgConfig,
} from '@talos/kyoko';
import { noop } from 'lodash';
import { Balances } from '../../components/Balances';
import { CreditExposuresBlotter } from '../../components/Blotters/CreditExposuresBlotter';
import { MobileBlotter } from '../../components/Blotters/MobileBlotter';
import { OrderHistoryBlotter } from '../../components/Blotters/OrderHistoryBlotter';
import { TradesBlotter } from '../../components/Blotters/TradesBlotter';
import { TransfersBlotter } from '../../components/Blotters/TransfersBlotter';
import { CandleSticksWithSymbol } from '../../components/Candlesticks/CandleSticksWithSymbol';
import { OrderCards } from '../../components/OrderCards';
import { OrderForms } from '../../components/OrderForms';
import { RFQOrderForm } from '../../components/OrderForms/RFQForm';
import { Settings } from '../../components/Settings';
import { VolumeLadder } from '../../components/VolumeLadder';
import { MobileWatchList } from '../../components/WatchList/MobileWatchList';
import { ScreenOrientationEnum } from '../types';

export const WatchListScreen = {
  component: <MobileWatchList securityRoute="/chart" />,
  label: 'watchlist' as const,
  path: '/',
  icon: IconName.ViewList,
  orientation: ScreenOrientationEnum.portrait,
};

export const OrderFormsScreen = {
  component: <OrderForms />,
  label: 'trade' as const,
  path: '/trade',
  icon: IconName.TrendUp,
  orientation: ScreenOrientationEnum.portrait,
};

export const RFQFormScreen = {
  component: <RFQOrderForm />,
  label: 'trade' as const,
  path: '/trade',
  icon: IconName.TrendUp,
  orientation: ScreenOrientationEnum.portrait,
};

export const OrderCardsScreen = {
  component: <OrderCards />,
  label: 'orders' as const,
  path: '/orders',
  icon: IconName.Collection,
};

export const BalancesScreen = {
  component: <Balances />,
  label: 'balances' as const,
  path: '/balances',
  icon: IconName.ChartDonut,
};

export const TradesScreen = {
  component: (
    <MobileBlotter titleKey="trades">
      <TradesBlotter
        renderInBackground={true}
        loading={false}
        maxRows={DEFAULT_MAX_ROWS}
        onStartLoading={noop}
        onFinishedLoading={noop}
      />
    </MobileBlotter>
  ),
  label: 'trades' as const,
  path: '/trades',
  icon: IconName.DocumentText,
};

export const CreditScreen = {
  component: (
    <MobileBlotter titleKey="credit">
      <CreditExposuresBlotter renderInBackground={true} />
    </MobileBlotter>
  ),
  label: 'credit' as const,
  path: '/credit',
  icon: IconName.CurrencyDollar,
};

export const TransfersScreen = {
  component: (
    <MobileBlotter titleKey="transfers">
      <TransfersBlotter renderInBackground={true} maxRows={DEFAULT_MAX_ROWS} />
    </MobileBlotter>
  ),
  label: 'transfers' as const,
  path: '/transfers',
  icon: IconName.ArrowLeftRight,
  requiredPermissions: [WL_VIEW_BLOTTERS_TRANSFERS],
  requiredConfigs: ['enableBalanceTransactions'] satisfies (keyof WLOrgConfig)[],
};

export const OrdersScreen = {
  component: (
    <MobileBlotter titleKey="orders">
      <OrderHistoryBlotter
        renderInBackground={true}
        loading={false}
        maxRows={DEFAULT_MAX_ROWS}
        onStartLoading={noop}
        onFinishedLoading={noop}
      />
    </MobileBlotter>
  ),
  label: 'orders' as const,
  path: '/orders',
  icon: IconName.DocumentText,
};

export const SettingsScreen = {
  component: <Settings />,
  label: 'settings' as const,
  path: '/settings',
  icon: IconName.Cog,
  requiredPermissions: [WL_VIEW_SETTINGS],
};

export const LadderScreen = {
  component: <VolumeLadder />,
  label: 'ladder' as const,
  path: '/ladder',
  icon: IconName.Filter,
  orientation: ScreenOrientationEnum.portrait,
};
export const ChartScreen = {
  component: <CandleSticksWithSymbol />,
  label: 'chart' as const,
  path: '/chart',
  icon: IconName.PresentationChartLine,
};
