import {
  BlotterTable,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  Divider,
  EmptyState,
  EmptyStateContent,
  EmptyStateWrapper,
  FormControlSizes,
  FormattedMessage,
  IconName,
  MixpanelEvent,
  Toggle,
  WL_VIEW_BLOTTERS_WITHDRAW,
  createCSVFileName,
  useDisclosure,
  useIntl,
  useMixpanel,
  useWLOrgConfigContext,
  useWLRoleAuth,
} from '@talos/kyoko';
import { useCallback, useState } from 'react';
import { defineMessages } from 'react-intl';
import { useTheme } from 'styled-components';
import { WithdrawDialog } from '../../../components/Dialogs';
import { BlotterActions } from '../styles';
import type { TabbedBlotterProps } from '../types';
import { useBalancesBlotter } from './hooks/useBalancesBlotter';

const messages = defineMessages({
  exportBalances: {
    defaultMessage: 'Export Balances',
    id: 'Blotters.BalancesBlotter.exportBalances',
  },
  withdraw: {
    defaultMessage: 'Withdraw',
    id: 'Blotters.BalancesBlotter.withdraw',
  },
  submitWithdrawRequest: {
    defaultMessage: 'Submit Withdraw Request',
    id: 'Blotters.BalancesBlotter.submitWithdrawRequest',
  },
  showZeroBalances: {
    defaultMessage: 'Show Zero Balances',
    id: 'Blotters.BalancesBlotter.showZeroBalances',
  },
});

export type BalanceBlotterProps = Required<Pick<TabbedBlotterProps, 'renderInBackground'>> & { colorful?: boolean };
export const BalancesBlotter = ({ renderInBackground: hidden, colorful }: BalanceBlotterProps) => {
  const theme = useTheme();
  const { baseSize } = theme;
  const { isAuthorized } = useWLRoleAuth();
  const { config } = useWLOrgConfigContext();
  const [showZeroBalances, setShowZeroBalances] = useState(false);
  const mixpanel = useMixpanel();
  const withdrawDialog = useDisclosure();
  const { formatMessage } = useIntl();

  const handleShowZeroBalances = useCallback(
    (checked: boolean) => {
      setShowZeroBalances(checked);
      mixpanel.track(MixpanelEvent.ToggleShowZeroBalances, { checked });
    },
    [mixpanel, setShowZeroBalances]
  );

  const handleWithdraw = useCallback(() => {
    withdrawDialog.open();
  }, [withdrawDialog]);

  const renderEmpty = useCallback(
    () => (
      <EmptyStateWrapper>
        <EmptyStateContent minHeight={baseSize * 7}>
          <EmptyState>
            <h2>{showZeroBalances ? 'There are no balances to show' : 'There are no non-zero balances to show'}</h2>
            {!showZeroBalances ? (
              <Button onClick={() => handleShowZeroBalances(true)} variant={ButtonVariants.Primary}>
                <FormattedMessage {...messages.showZeroBalances} />
              </Button>
            ) : null}
          </EmptyState>
        </EmptyStateContent>
      </EmptyStateWrapper>
    ),
    [baseSize, handleShowZeroBalances, showZeroBalances]
  );

  const { blotterTable, filterBuilderAccordion } = useBalancesBlotter({
    renderEmpty,
    showZeroBalances,
    colorful,
  });

  const handleExportBalances = useCallback(() => {
    blotterTable.exportDataAsCSV({
      includeHiddenColumns: true,
      fileName: createCSVFileName({
        name: 'Balances',
      }),
    });
    mixpanel.track(MixpanelEvent.ExportBalances);
  }, [blotterTable, mixpanel]);

  return (
    <>
      {!hidden && (
        <BlotterTableFilters
          {...filterBuilderAccordion}
          {...blotterTable.blotterTableFiltersProps}
          showFilterBuilder={false}
          prefix={
            <BlotterActions>
              <Button size={FormControlSizes.Small} onClick={handleExportBalances}>
                <FormattedMessage {...messages.exportBalances} />
              </Button>
              <Divider orientation="vertical" my="spacingSmall" />
              <Toggle
                onChange={handleShowZeroBalances}
                checked={showZeroBalances}
                label={formatMessage(messages.showZeroBalances)}
                size={FormControlSizes.Small}
              />
              {config.enableBalanceTransactions && isAuthorized(WL_VIEW_BLOTTERS_WITHDRAW) && (
                <>
                  <Divider orientation="vertical" my="spacingSmall" />
                  <Button
                    startIcon={IconName.Upload}
                    size={FormControlSizes.Small}
                    variant={ButtonVariants.Default}
                    onClick={handleWithdraw}
                  >
                    <FormattedMessage {...messages.withdraw} />
                  </Button>
                </>
              )}
            </BlotterActions>
          }
        />
      )}
      <BlotterTable {...blotterTable!} />
      <WithdrawDialog
        {...withdrawDialog}
        title={formatMessage(messages.withdraw)}
        showCancel={false}
        confirmLabel={formatMessage(messages.submitWithdrawRequest)}
        width={500}
        alignContent="left"
        autoFocusFirstElement={false}
        showClose={true}
      />
    </>
  );
};
