import { hsla, lighten } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { lightColors, mapLightTheme } from './LightTheme';

const colors: Colors = {
  black: {
    default: '#000000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#FFFFFF',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.1)',
  },

  gray: {
    main: '#FFFFFF',
    '000': '#F1F1F1',
    '010': '#F8F8F8',
    '020': '#ececec',
    '030': hsla(240, 0.12, 0.91, 1),
    '040': hsla(240, 0.06, 0.85, 1),
    '050': hsla(240, 0.06, 0.81, 1),
    '060': hsla(240, 0.06, 0.77, 1),
    '070': hsla(240, 0.06, 0.73, 1),
    '080': '#193843',
    '090': '#031B23',
    '100': '#031B23',
  },

  primary: {
    mute: setAlpha(0.1, '#3C85DD'),
    dim: setAlpha(0.4, '#3C85DD'),
    default: '#2A68B2',
    lighten: '#3C85DD',
  },

  green: {
    mute: setAlpha(0.1, '#2FC06A'),
    dim: setAlpha(0.4, '#2FC06A'),
    default: '#00D64A',
    lighten: '#2FC06A',
  },

  red: {
    mute: setAlpha(0.1, '#f57a7a'),
    dim: setAlpha(0.4, '#f57a7a'),
    default: '#EC5B5B',
    lighten: '#f57a7a',
  },

  yellow: {
    mute: setAlpha(0.1, '#F2D264'),
    dim: setAlpha(0.4, '#F2D264'),
    default: '#F2D264',
    lighten: lighten(0.1, '#F2D264'),
  },

  blue: {
    mute: setAlpha(0.1, '#3C85DD'),
    dim: setAlpha(0.4, '#3C85DD'),
    default: '#2A68B2',
    lighten: '#3C85DD',
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },

  purple: lightColors.purple,
};

export const LightWintermute2Theme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightWM2',

  spacingLayout: 8,
  backgroundHeader: setAlpha(0.75, colors.gray.main),
  borderColorHeader: 'transparent',
  backgroundBody: '#E5E6E5',
  backgroundInput: colors.gray['020'],
  backgroundSelect: colors.gray['020'],
  backgroundSelectHover: colors.gray['040'],
  backgroundSelectFocus: colors.gray['030'],
  backgroundSelectInvalid: colors.gray['020'],
  backgroundSelectDisabled: colors.gray['010'],
  backgroundSelectReadOnly: colors.gray['010'],

  colorTextPositiveButtonDisabled: colors.black.default,
  colorTextNegativeButtonDisabled: colors.black.default,
  colorTextPrimaryButtonDisabled: colors.black.default,
  colorTextPriorityButtonDisabled: colors.black.default,

  backgroundNegativeButtonActive: colors.red.default,
  backgroundNegativeButtonFocus: colors.red.default,

  backgroundPositiveButtonActive: colors.green.default,
  backgroundPositiveButtonFocus: colors.green.default,

  backgroundPrimaryButtonActive: colors.primary.default,
  backgroundPrimaryButtonFocus: colors.primary.default,

  borderColorChartAxis: colors.gray['040'],
  borderColorChartGridLine: colors.gray['040'],
  borderColorChartCrosshair: colors.gray['070'],
  backgroundChartUpColor: colors.green.default,
  backgroundChartDownColor: colors.red.default,
  borderColorChartUpColor: colors.green.default,
  borderColorChartDownColor: colors.red.default,
  backgroundChart: colors.gray.main,

  backgroundToggle: colors.gray['040'],
  backgroundDefaultButtonHover: setAlpha(0.2, colors.gray['100']),
  backgroundDefaultButtonFocus: setAlpha(0.2, colors.gray['100']),
  backgroundDefaultButtonActive: setAlpha(0.1, colors.gray['100']),
  backgroundDefaultButtonDisabled: setAlpha(0.05, colors.gray['100']),

  colorOrderStatusFilled: colors.green.default,
  colorOrderStatusRejected: colors.yellow.default,

  colorScrollbar: setAlpha(0.6, colors.gray['060']),
  colorScrollbarHover: setAlpha(0.5, colors.gray['080']),

  chamferMultiplier: 1,
  borderRadiusContent: 12,
  buttonGroupSharedBackground: colors.gray['030'],
  backgroundMutedButton: 'transparent',
  colorTextMutedButton: colors.gray['090'],
});
