import { orderStatusTextToColor, type OrderStatusText } from '@talos/kyoko';
import styled, { keyframes } from 'styled-components';

const slideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
`;

export const ExecutionCardWrapper = styled.div<{ status: OrderStatusText; attached: boolean }>`
  border-top: solid 4px ${({ status, theme }) => orderStatusTextToColor(status, theme)};
  padding: ${({ theme }) => theme.spacingMedium}px;
  background: ${({ theme }) => theme.backgroundContent};
  backdrop-filter: blur(10px);
  position: ${({ attached }) => (attached ? 'absolute' : 'relative')};
  bottom: 0;
  width: 100%;
  z-index: 2;
  transition: border 200ms ease;
  animation: ${slideUp} 200ms ease-out forwards;

  > button {
    position: absolute;
    top: ${({ theme }) => theme.spacingDefault}px;
    right: ${({ theme }) => theme.spacingDefault}px;
  }
`;

export const Header = styled.div`
  margin-bottom: ${({ theme }) => theme.spacingLarge}px;
  color: ${({ theme }) => theme.colorTextImportant};
  display: flex;
  justify-content: center;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacingDefault}px;
`;

export const Label = styled.span`
  padding-right: ${({ theme }) => theme.spacingTiny}px;
`;

export const Value = styled.span`
  color: ${({ theme }) => theme.colorTextImportant};
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacingDefault}px;

  > *:first-child {
    flex: 1;
  }
`;

export const Section = styled.div`
  margin-bottom: ${({ theme }) => theme.spacingBig}px;
`;

export const Footer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacingMedium}px;

  button {
    flex: 1;
  }
`;

export const ParametersSection = styled.div`
  border-left: solid 1px ${({ theme }) => theme.borderColorDivider};
  padding-left: ${({ theme }) => theme.spacingDefault}px;
  font-size: ${({ theme }) => theme.fontSizeTiny}rem;
`;
