import { Box, Grid } from '@talos/kyoko';
import styled from 'styled-components';

export const MobileHeaderWrapper = styled(Box)`
  background: ${({ theme }) => theme.backgroundHeader};
  border-bottom: solid 1px ${({ theme }) => theme.borderColorHeader};
  backdrop-filter: ${({ theme }) => theme.backdropFilterHeader};
  box-shadow: ${({ theme }) => theme.boxShadowHeader};
  padding-top: calc(${({ theme }) => theme.spacingDefault}px + env(safe-area-inset-top));
`;
MobileHeaderWrapper.defaultProps = {
  px: 'spacingComfortable',
  pb: 'spacingDefault',
};

export const HeaderContent = styled.div<{ maxWidth?: number }>`
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : '')}
  display: flex;
  align-items: center;
  margin: 0 auto;
  gap: 0 ${({ theme }) => theme.spacingMedium}px;
  height: 38px;
`;

export const Section = styled(Grid)`
  grid-auto-flow: column;
  align-items: center;
  grid-gap: ${({ theme }) => theme.spacingDefault}px;
`;
