import type { ValueGetterParams } from 'ag-grid-community';
import { Transfer } from '../../../types';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

export interface TransferDescriptionColumnParams {
  /** If true, then show 'unknown' instead of ID's */
  showUnknownIfMarketAccountMissing: boolean;
}

export const transferDescription: ColDefFactory<Column<TransferDescriptionColumnParams>> = column => {
  const avoidMarketIdDisplay = column.params?.showUnknownIfMarketAccountMissing ?? false;

  return {
    ...baseColumn(column),
    valueGetter: (params: ValueGetterParams<Transfer>): string | undefined => {
      const transfer = params.node?.data;
      if (!transfer || !(transfer instanceof Transfer)) {
        return undefined;
      }

      return transfer.getTransferDescription(params.context.current.marketAccountsByID, avoidMarketIdDisplay);
    },
  };
};
