import {
  GlobalCustomerUserConfigTradingLayoutArrangements,
  logger,
  useWLLayoutArrangement,
  useWLOrgConfigContext,
  useWLRoleAuth,
  useWLUser,
} from '@talos/kyoko';
import { useMemo } from 'react';
import { checkRequiredPermissions } from 'utils/layoutPermissions';
import { useCurrentNavigationItem } from '../../hooks/useCurrentNavigationItem';
import { LayoutSpecs, type LayoutType } from '../../layouts/layout';
import type { LayoutComponent, MultiLayoutComponent, SingleLayoutComponent } from '../../layouts/types';

/**
 * This function takes what was a traditional "LayoutComponent", but which can now be *either* a SingleLayoutComponent
 * *or* a MultiLayoutComponent, and duck-types it to check for an "arrangements" key on the data blob. If it finds one,
 * it considers this a MultiLayoutComponent, and uses the "arrangements" value as a map of "arrangement" key names to
 * LayoutComponentArrangement objects. If it finds only a SingleLayoutComponent (meaning the absence of an "arrangement")
 * key, it returns the arrangement as-is, *as* a "SingleLayoutComponent". If it *does* find an "arrangement" key on the
 * existing template object, it uses the 2nd parameter (arrangement) to look up a value within that hash, and return the
 * LayoutComponentArrangement object that matches this name. If it fails to find a match, it returns the arrangement named
 * by the DEFAULT_ARRANGEMENT key value, or otherwise, it finds an existing value by the "arrangement" lookup value, and
 * then merged the 2 objects together into a single "LayoutComponent" object, which it returns as a "SingleLayoutComponent"
 * type, so that all downstream consumers can simply treat it as a "LayoutComponent" or "SingleLayoutComponent", both of
 * which are interchangeable type names.
 *
 * @param layoutComponent {SingleLayoutComponent | MultiLayoutComponent} -- A component reference for the current Layout
 * (e.b. SimpleRFQLayout, TradingLayout, etc).
 * @param arrangement Optional: {string} -- A stored layout arrangement template name to look up on the current template
 * *if* it is a MultiLayoutComponent (defaults to the name "default").
 */
const DEFAULT_ARRANGEMENT = GlobalCustomerUserConfigTradingLayoutArrangements.SingleSymbolView;
function useSingleLayoutComponent<
  LC extends SingleLayoutComponent | MultiLayoutComponent,
  A extends string | undefined
>(layoutComponent: LC, userConfigLayoutArrangement: A): SingleLayoutComponent;
function useSingleLayoutComponent(layoutComponent, userConfigLayoutArrangement): SingleLayoutComponent {
  const { config } = useWLOrgConfigContext();
  const { layoutArrangement: siteConfigLayoutArrangement = '' } = config;

  return useMemo(() => {
    if ('arrangements' in layoutComponent) {
      return {
        ...layoutComponent,
        ...(layoutComponent.arrangements[userConfigLayoutArrangement] ??
          layoutComponent.arrangements[siteConfigLayoutArrangement] ??
          layoutComponent.arrangements[DEFAULT_ARRANGEMENT]),
      };
    }

    return layoutComponent;
  }, [userConfigLayoutArrangement, siteConfigLayoutArrangement, layoutComponent]);
}

export const useLayoutComponent = (layoutType: LayoutType, isMobile: boolean): LayoutComponent => {
  const user = useWLUser();
  const { isAuthorized } = useWLRoleAuth();
  const { layoutArrangement } = useWLLayoutArrangement();

  const currentNavItem = useCurrentNavigationItem(isMobile);

  const layoutSpec = LayoutSpecs[currentNavItem?.layoutComponent ?? layoutType];

  // Find the first spec that satisfies the given list of permissions
  const foundSpec = layoutSpec?.find(spec => checkRequiredPermissions(spec.requiredPermissions, isAuthorized));
  if (foundSpec == null) {
    window.location.href = '/unauthorized';
    logger.error(new Error('Could not find a layout spec satisfying user permissions'), {
      extra: {
        layoutSpec,
        user,
      },
    });
    throw new Error('Error: Could not find a layout spec satisfying the permissions.');
  }

  return useSingleLayoutComponent(foundSpec.layout, layoutArrangement);
};
