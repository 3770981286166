import type { SeriesSunburstOptions, TooltipFormatterContextObject, TooltipOptions } from 'highcharts/highcharts';
import { useMemo, type ReactElement } from 'react';
import { useTheme, type DefaultTheme } from 'styled-components';
import { useCurrency } from '../../../../hooks/useCurrency';
import { useIntlContext } from '../../../../hooks/useIntlContext';
import { renderToHTML } from '../../../../utils/render';
import { SunburstTooltip } from '../SunburstTooltip';
import type { UseChartTooltipProps } from '../types';

export function useSunburstTooltip({
  valueType,
  currency,
  omittedKeys = HOLE,
}: { omittedKeys?: string[] } & Omit<UseChartTooltipProps, 'shared'>): TooltipOptions {
  const homeCurrencyInfo = useCurrency(currency);
  const theme = useTheme();
  const { locale } = useIntlContext();
  return useMemo(() => {
    return {
      useHTML: true,
      padding: 0,
      backgroundColor: 'transparent',
      formatter: function <TReturn = string>(
        this: TooltipFormatterContextObject & {
          renderFunction?: (component: ReactElement, theme: DefaultTheme) => TReturn;
          shared?: boolean;
          series?: SeriesSunburstOptions;
        }
      ) {
        const renderFunction = this.renderFunction ?? renderToHTML;

        if (this && this.key && !omittedKeys.includes(this.key)) {
          return renderFunction(
            <SunburstTooltip data={this} homeCurrency={homeCurrencyInfo} valueType={valueType} />,
            theme,
            locale
          );
        } else {
          return false;
        }
      } as unknown as TooltipOptions['formatter'],
      valueType,
      homeCurrencyInfo,
    };
  }, [valueType, homeCurrencyInfo, omittedKeys, theme, locale]);
}
const HOLE = ['hole'];
