import { every, isNil } from 'lodash';
import { Children, createContext, useCallback, useContext, type ReactNode } from 'react';
import styled from 'styled-components';
import { Button, IconButton, type ButtonProps, type ButtonVariants } from '../Button';
import { Box, Flex } from '../Core';
import { IconName } from '../Icons';
import { Popover, usePopoverState } from '../Popover';

const MenuContext = createContext<MenuContextProps | undefined>(undefined);
MenuContext.displayName = 'MenuContext';

interface MenuContextProps {
  closeOnSelect: boolean;
  close(): void;
}

interface MenuProps {
  children?: ReactNode;
  /** Content to use as the menu button instead of the icon */
  triggerContent?: ReactNode;
  triggerIcon?: IconName;
  triggerMinimal?: boolean;
  triggerRound?: boolean;
  triggerVariant?: ButtonVariants;
  closeOnSelect?: boolean;
  'data-testid'?: string;
}

export function Menu({
  children,
  triggerContent,
  triggerIcon = IconName.DotsVertical,
  triggerMinimal = true,
  triggerRound = true,
  triggerVariant,
  closeOnSelect = true,
  'data-testid': dataTestId,
}: MenuProps) {
  const popover = usePopoverState({
    trigger: 'click',
    usePortal: true,
    placement: 'bottom-end',
    closeOnClickOutside: true,
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
        },
      },
    ],
  });

  const { onClickTarget, close } = popover;

  const handleClickTarget = useCallback(
    (e: Event) => {
      onClickTarget(e);
      e.stopPropagation();
    },
    [onClickTarget]
  );

  if (every(Children.toArray(children), child => isNil(child))) {
    return null;
  }

  return (
    <MenuContext.Provider value={{ closeOnSelect, close }}>
      <Flex alignItems="center" data-testid={dataTestId}>
        <Popover {...popover} onClickTarget={handleClickTarget}>
          {triggerContent ? (
            <Box cursor="pointer">{triggerContent}</Box>
          ) : (
            <IconButton
              icon={triggerIcon}
              ghost={triggerMinimal}
              round={triggerRound}
              variant={triggerVariant}
              data-testid="menu-icon-button-trigger"
              prominent
            />
          )}
          <Flex flexDirection="column" alignItems="stretch" gap="spacingSmall">
            {children}
          </Flex>
        </Popover>
      </Flex>
    </MenuContext.Provider>
  );
}

export const MenuGroup = styled(Flex).attrs({
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: 'spacingSmall',
})`
  padding: calc(var(--spacingDefault) * 1px) 0;
  border-top: 1px solid var(--borderColorDivider);
  border-bottom: 1px solid var(--borderColorDivider);
  margin: calc(var(--spacingSmall) * 1px) 0;
  &:first-child {
    padding-top: 0;
    margin-top: 0;
  }
  &:first-child,
  & + & {
    border-top: none;
    margin-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
  }
`;

export const MenuItem = styled(function MenuItem({
  onClick,
  as,
  ...props
}: ButtonProps & { as?: Parameters<typeof Button>[0]['as'] }) {
  const { closeOnSelect, close } = useContext(MenuContext)!;
  const handleClick = useCallback(
    e => {
      onClick?.(e);
      if (closeOnSelect) {
        close();
      }
    },
    [onClick, close, closeOnSelect]
  );
  return <Button forwardedAs={as} ghost={true} {...props} onClick={handleClick} />;
})`
  justify-content: flex-start;
  span + i {
    margin-left: auto;
  }
`;
