import {
  ModeEnum,
  ProductTypeEnum,
  SettleValueTypeEnum,
  type FeeModeEnum,
  type ISecurity,
  type InstrumentCompositionEnum,
  type LegDirectionEnum,
  type OptionTypeEnum,
  type SyntheticProductTypeEnum,
} from './types';

export interface MultilegMarketDetails {
  Market: string;
  MarketAccount: string;
}

export interface Security {
  Timestamp: string;
  Symbol: string;
  MinAmtIncrement?: string;
  MinPriceIncrement: string;
  MinSizeIncrement: string;
  MinimumSize: string;
  MaximumSize: string;
  MinimumAmount?: string;
  QuoteCurrency: string;
  BaseCurrency: string;
  DefaultPriceIncrement: string;
  DefaultSizeIncrement: string;
  PriceDisplaySpec: string;
  SizeDisplaySpec: string;
  NormalSize: string;
  ProductType: ProductTypeEnum;
  PositionCurrency?: string;
  SettlementCurrency: string;
  SettleValueType?: SettleValueTypeEnum;
  NotionalMultiplier: string;
  DisplaySymbol: string;
  Description: string;
  Markets?: string[];
  Expiration?: string;
  UnderlyingQuoteCurrency?: ISecurity['UnderlyingQuoteCurrency'];
  UnderlyingSymbol?: ISecurity['UnderlyingSymbol'];
  UnderlyingCode?: string;
  OptionType?: OptionTypeEnum;
  StrikePrice?: string;
  Mode?: ModeEnum;
  DefaultPricingRuleMode?: ModeEnum;
  Rank?: number;
  Composition?: InstrumentCompositionEnum;
  EndTime?: string;
  SupportedPricingModes?: {
    ImpliedVolatility?: boolean;
    UnderlyingQuoteCurrency?: boolean;
  };
  // Generated locally to help with autocompletes
  searchSymbol?: string;
  MultilegDetails?: {
    FeeMode: FeeModeEnum;
    SyntheticProductType: SyntheticProductTypeEnum;
    MaxMarketDataDepth: number;
    Legs: {
      Direction: LegDirectionEnum;
      Index: number;
      Markets?: MultilegMarketDetails[];
      Symbol: string;
    }[];
    Parameters: {
      LegParams: (Record<string, string | number | boolean> & {
        Initiating: boolean;
      })[];
    };
  };
  Legs?: {
    Direction: LegDirectionEnum;
    Symbol: string;
  }[];
}

export const EMPTY_SECURITY: Security & { SizeBuckets: unknown[] } = {
  Timestamp: '',
  Symbol: '',
  MinPriceIncrement: '',
  MinSizeIncrement: '',
  MinimumSize: '',
  MaximumSize: '',
  QuoteCurrency: '',
  BaseCurrency: '',
  DefaultPriceIncrement: '',
  DefaultSizeIncrement: '',
  PriceDisplaySpec: '',
  SizeDisplaySpec: '',
  NormalSize: '',
  ProductType: ProductTypeEnum.Spot,
  PositionCurrency: '',
  SettlementCurrency: '',
  SettleValueType: SettleValueTypeEnum.Regular,
  NotionalMultiplier: '',
  DisplaySymbol: '',
  Description: '',
  SizeBuckets: [],
  Markets: [],
  Expiration: '',
  Mode: ModeEnum.Enabled,
  DefaultPricingRuleMode: ModeEnum.Enabled,
};

// Mirrors the backend API
export interface MultilegModel {
  Symbol: string;
  Description: string;
  DisplaySymbol: string;
  ProductType: ProductTypeEnum;
  SyntheticProductType: SyntheticProductTypeEnum;
  MinSizeIncrement: string;
  MinPriceIncrement: string;
  MaxMarketDataDepth: number;
  FeeMode?: FeeModeEnum;
  ReqLegs: {
    Symbol: string;
    Markets: string[];
  }[];
  Parameters: {
    LegParams: {
      Initiating: boolean;
    }[];
  };
}
