import {
  AccordionGroup,
  Box,
  ChartTooltipValueType,
  Flex,
  InlineFormattedNumber,
  SunburstChart,
  VStack,
  getColorVariations,
  getCurrencyColor,
  notEmpty,
  useConstant,
  useIntl,
  useObservableValue,
  useScreenOrientation,
  useStaticSubscription,
  useSunburstTooltip,
  useWLHomeCurrency,
  wsScanToDoubleMap,
  wsScanToMap,
  type Balance,
} from '@talos/kyoko';
import Big from 'big.js';
import type { SeriesSunburstOptions } from 'highcharts';
import { useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { map } from 'rxjs';
import { useTheme } from 'styled-components';
import { BalancesBlotter } from '../Blotters/BalancesBlotter';

const balancesMessages = defineMessages({
  totalBalances: {
    defaultMessage: 'Total Balances',
    id: 'Balances.totalBalances',
  },
});

const HOLE = 'hole';
export const Balances = () => {
  const intl = useIntl();
  const theme = useTheme();
  const { homeCurrency } = useWLHomeCurrency();
  const request = useConstant({
    name: 'Balance',
    tag: 'BLOTTER_BALANCES',
    versionBy: 'Revision',
    Throttle: '1s',
    EquivalentCurrency: homeCurrency,
    ShowZeroBalances: false,
  });
  const { data: sub } = useStaticSubscription<Balance>(request);
  const total = useObservableValue(
    () =>
      sub.pipe(
        wsScanToMap({
          getUniqueKey: data => `${data.Currency}_${data.MarketAccount}`,
          newMapEachUpdate: false,
        }),
        map(map => [...map.values()].reduce((sum, res) => sum.add(res.AvailableAmount), Big(0)))
      ),
    [sub]
  );
  const series = useObservableValue(
    () =>
      sub.pipe(
        wsScanToDoubleMap({
          getKey1: data => data.Currency,
          getKey2: data => `${data.Currency}_${data.MarketAccount}`,
          newOuterMapEachUpdate: false,
          newInnerMapsEachUpdate: false,
        }),
        map(map => {
          const currencies = [...map.keys()];
          const dataPoints = currencies
            .flatMap(currency => {
              const accounts = map.get(currency);
              if (!accounts) {
                return undefined;
              }
              const accountsList = [...accounts.values()];

              const total = accountsList
                .filter(account => account.Equivalent?.Amount)
                .reduce((res, account) => res.add(account.Equivalent?.Amount ?? 0), Big(0))
                .toNumber();
              return [
                {
                  id: currency,
                  name: currency,
                  parent: HOLE,
                  value: total,
                  color: getCurrencyColor(currency),
                },
                ...accountsList
                  .filter(account => account.Equivalent?.Amount)
                  .map((account, index) => ({
                    parent: account.Currency,
                    color: getColorVariations(getCurrencyColor(currency), index, accountsList.length, theme.type, 0.25),
                    name: `${account.Currency} - ${account.MarketAccount}`,
                    value: parseFloat(account.Equivalent?.Amount),
                  })),
              ];
            })
            .sort((a, b) =>
              Big(b?.value ?? 0)
                .minus(a?.value ?? 0)
                .toNumber()
            )
            .filter(notEmpty);
          return [
            {
              type: 'sunburst' as const,
              dataLabels: {
                enabled: true,
                useHTML: true,
                style: {
                  fontFamily: theme.fontFamily,
                  fontWeight: 'normal',
                },
                rotation: 0,
                formatter: function () {
                  return this.point.parent === HOLE ? this.key : '';
                },
              },
              data: [
                {
                  id: HOLE,
                  color: 'transparent',
                },
                ...dataPoints,
              ],
              levels: [
                {
                  level: 1,
                },
                {
                  level: 2,
                  levelSize: { value: 20, unit: 'percentage' },
                  colorByPoint: true,
                },
                {
                  level: 3,
                  levelSize: { value: 10, unit: 'percentage' },
                  colorByPoint: false,
                },
              ],
            },
          ] satisfies SeriesSunburstOptions[];
        })
      ),
    [sub, theme.fontFamily, theme.type],
    []
  );

  const orientation = useScreenOrientation();
  const isPortrait = orientation === 'portrait-primary' || orientation === 'portrait-secondary';

  const tooltip = useSunburstTooltip({ valueType: ChartTooltipValueType.currency, currency: homeCurrency });

  const options = useMemo(
    () => ({
      tooltip,
    }),
    [tooltip]
  );

  return (
    <Flex flexDirection="column" h="100%">
      {isPortrait && (
        <Box aspectRatio="1/1" style={{ borderBottom: `solid 2px ${theme.backgroundBody}` }} position="relative">
          <VStack position="absolute" fontSize="fontSizeLarge" top={0} left={0} bottom={0} right={0} gap="spacingSmall">
            <Box fontSize="fontSizeDefaut">{intl.formatMessage(balancesMessages.totalBalances)}</Box>
            <InlineFormattedNumber number={total} increment="0.01" currency={homeCurrency} />
          </VStack>
          <SunburstChart series={series} options={options} />
        </Box>
      )}
      <Box flex="1">
        <AccordionGroup>
          <BalancesBlotter colorful={true} renderInBackground={true} />
        </AccordionGroup>
      </Box>
    </Flex>
  );
};
