import type { LayoutComponentScreen } from '../types';
import { BalancesScreen, CreditScreen, OrdersScreen, TradesScreen, TransfersScreen } from './screenLibrary';

export const blottersOnlyMobileScreens: LayoutComponentScreen[] = [
  OrdersScreen,
  TradesScreen,
  BalancesScreen,
  CreditScreen,
  TransfersScreen,
];
