import { AccordionGroup, Box, Divider, useIntl } from '@talos/kyoko';
import type { PropsWithChildren } from 'react';
import { MobileLabelMessages } from '../../layouts/mobileScreens/messages';

export function MobileBlotter({
  titleKey,
  children,
}: PropsWithChildren<{ titleKey: keyof typeof MobileLabelMessages }>) {
  const { formatMessage } = useIntl();
  return (
    <AccordionGroup>
      <Box p="spacingComfortable" color="colorTextImportant">
        {formatMessage(MobileLabelMessages[titleKey])}
      </Box>
      <Divider />
      {children}
    </AccordionGroup>
  );
}
