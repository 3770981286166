import type { ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { get } from 'lodash';
import { SubAccountReconMatch, type SubAccountReconMatchStatusEnum } from '../../../types';
import { RECON_MATCH_STATUS_TEXT_MAPPING, ReconMatchStatus } from '../../Status';
import { stringColumnComparator } from '../columns/utils';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

interface ReconMatchStatusValue {
  status: SubAccountReconMatchStatusEnum | undefined;
  comments: string[] | undefined;
}

export const reconMatchStatus: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  valueGetter: ({ data }: ValueGetterParams<unknown>): ReconMatchStatusValue => {
    // grab the .Status using the field, try to get comments if the data is of the correct type
    const status: SubAccountReconMatchStatusEnum | undefined = column.field ? get(data, column.field) : undefined;
    return { status, comments: data instanceof SubAccountReconMatch ? data.Comments : undefined };
  },
  filterValueGetter: ({ data }: ValueGetterParams<unknown>) => {
    return column.field ? get(data, column.field) : undefined;
  },
  cellRenderer: ({ value }: ICellRendererParams<SubAccountReconMatch, ReconMatchStatusValue>) => {
    if (!value || value.status == null) {
      return null;
    }

    return <ReconMatchStatus status={value.status} comments={value.comments} />;
  },
  valueFormatter: ({ value }: ValueFormatterParams<unknown, ReconMatchStatusValue>) => {
    if (!value || value.status == null) {
      return '';
    }

    return RECON_MATCH_STATUS_TEXT_MAPPING[value.status];
  },
  comparator: (valueA: ReconMatchStatusValue | undefined, valueB: ReconMatchStatusValue | undefined) => {
    return stringColumnComparator(valueA?.status, valueB?.status);
  },
});
