import React, { createContext, useMemo } from 'react';
import { useSocketClient } from '../../WebSocketClientProvider';
import { useWLOrdersProvider } from '../../WLOrdersProvider';
import { useWLOrderFormStore } from '../WLOrderFormStore';
import { OrderService } from './OrderService';

type WLOrderServiceContextProps = { orderService: OrderService };
export const WLOrderServiceContext = createContext<WLOrderServiceContextProps | undefined>(undefined);

export function WLOrderServiceProvider(props: React.PropsWithChildren) {
  const store = useWLOrderFormStore();
  const webSocketClient = useSocketClient();
  const { sessionOrdersObs } = useWLOrdersProvider();
  const service = useMemo(
    () =>
      new OrderService(
        store,
        webSocketClient,
        import.meta.env.VITE_IS_MOBILE ? { dismissesActionAfterTimeout: false } : undefined,
        sessionOrdersObs
      ),
    [store, webSocketClient, sessionOrdersObs]
  );

  return (
    <WLOrderServiceContext.Provider value={{ orderService: service }}>{props.children}</WLOrderServiceContext.Provider>
  );
}

export const useWLOrderServiceContext = () => {
  const context = React.useContext(WLOrderServiceContext);
  if (context === undefined) {
    throw new Error('Missing OrderServiceContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};
