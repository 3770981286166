import type * as Highcharts from 'highcharts/highcharts';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { BaseChart, type BaseChartProps } from './BaseChart';
import { useChartOptionsOverrides, useHighchartsRef, useSeriesOptions } from './hooks';
import type { SharedChartProps } from './types';

export interface SunburstChartProps extends SharedChartProps<Highcharts.SeriesSunburstOptions>, BaseChartProps {}

export function SunburstChart({ series, seriesObservable, options, onChartCreated, ...props }: SunburstChartProps) {
  const { chartRef, isLoaded, setChartObject } = useHighchartsRef({ onChartCreated });

  const theme = useTheme();
  // @ts-expect-error borderColor should allow null
  const defaultOptions: Highcharts.Options = useMemo(
    () => ({
      chart: {
        type: 'sunburst',
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        sunburst: {
          dataLabels: {
            enabled: false,
          },
          borderColor: null,
          borderWidth: 0.5,
          states: {
            hover: {
              borderColor: theme.backgroundContent,
              borderWidth: 2,
              brightness: 0.2,
              opacity: 1,
            },
            inactive: {
              opacity: 0.5,
              enabled: true,
            },
          },
        },
      },
    }),
    [theme]
  );

  const seriesOptions = useSeriesOptions({ series, seriesObservable, chartRef, isLoaded });
  const combinedOptions = useChartOptionsOverrides(defaultOptions, options, seriesOptions);

  return <BaseChart options={combinedOptions} onChartCreated={setChartObject} {...props} />;
}
