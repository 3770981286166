import type { LayoutComponentScreen } from '../types';
import {
  BalancesScreen,
  CreditScreen,
  OrderCardsScreen,
  RFQFormScreen,
  SettingsScreen,
  TradesScreen,
  TransfersScreen,
  WatchListScreen,
} from './screenLibrary';

export const simpleRFQMobileScreens: LayoutComponentScreen[] = [
  WatchListScreen,
  OrderCardsScreen,
  RFQFormScreen,
  BalancesScreen,
  TradesScreen,
  CreditScreen,
  TransfersScreen,
  SettingsScreen,
];
